import { useSelector } from 'react-redux'

import classes from './PageHeaders.module.css'
import Search from '../../images/Search.svg'
import Plus from '../../images/Plus-light small.svg'
import ButtonM from '../UI/Buttons/Buttons/ButtonM'
import { useEffect, useRef, useState } from 'react'
import FilterButton from '../UI/Buttons/FilterButton'
import SortBy from '../UI/Others/SortBy';
import LoadingSpinnerButtonLight from '../UI/LoadingSpinners/LoadingSpinnerButtonLight'



const PageHeaders = (props) => {
    const addPermission = useSelector((state) => state.auth.addPermission)

    const [total, setTotal] = useState()
    const [search, setSearch] = useState('')
    const typingTimeout = useRef(null); 

    useEffect(()=>{
      const i = parseFloat(props.total).toLocaleString()
      setTotal(i)
    },[props.total])

    useEffect(() => {
      setSearch(props.search)
    },[props.search])

    const handleStopTyping = (value) => {
      props.onChangeSearchbox(value)
    };

    const searchChangeHandler = (event) => {
      const value = event.target.value;
      setSearch(value)
      if (typingTimeout.current) {
        clearTimeout(typingTimeout.current);
      }
  
      if((value && value.length > 2) || !value || value === ''){
        typingTimeout.current = setTimeout(() => {
          handleStopTyping(value);
        }, 1000); 
      }
    };
    return (
        <header className={classes["header"]}>
        <h2 className={`h2 ${classes['header-title']}`}>All {props.title}s ({total})</h2>
        <div className={classes["header__items"]}>
        {props.hasSearch && <div className={classes["header__search"]}>
          <img src={Search} alt=''/>
          <input type="text" placeholder={"Search " + props.title} onChange={searchChangeHandler} value={search}/>
          {props.loading && <LoadingSpinnerButtonLight/>}
          </div>}
        <FilterButton onClick={props.onOpenFilter}/>
        <SortBy columns={props.columns} selectedColumn={props.orderBy} onChange={props.onChangeSort} desc={props.desc}/>
        {addPermission && <ButtonM className={classes["header__add-btn"]} onClick={props.onAdd}><img src={Plus} alt=''/><div>New {props.title}</div></ButtonM>}
      </div>
      </header>

    )
}

export default PageHeaders