import { Fragment, useEffect, useState, useRef } from "react"

import classes from './NewFeatures.module.css'
import MainHeader from "../../../layout/MainHeader"
import ButtonL from "../../../UI/Buttons/Buttons/ButtonL"
import ButtonLOut from '../../../UI/Buttons/Buttons-Outline/ButtonLOut'
import useHttp from "../../../../hooks/use-http"
import { useNavigate } from "react-router"
import LoadingSpinner from "../../../UI/LoadingSpinners/LoadingSpinner"
import JiraCredentials from "../JiraCredentials"
import ImplementedFeature from "./ImplementedFeature"
import FeaturesList from "./FeaturesList"
import { Link } from "react-router-dom"
import useOutsideAlerter from "../../../../hooks/use-outside-alerter"
import MessageBox from "../../../UI/Modals/MessageBox"

const NewFeatures = () => {
    const {sendRequest, isLoading } = useHttp()
    const descRef = useRef()
    const {resetState, clickedOutside} = useOutsideAlerter(descRef)
    const navigate = useNavigate()

    const [features, setFeatures] = useState([])
    const [implementedFeatures, setImplementedFeatures] = useState([])
    const [hasJiraCredentials, setHasJiraCredentials] = useState()
    const [showDescription, setShowDescription] = useState()
    const [lastFeatureDone, setLastFeatureDone] = useState()

    useEffect(()=>{
        if(clickedOutside){
           // setShowDescription(false)
           // resetState()
        }
    },[clickedOutside])

    useEffect(()=> {
        if(localStorage.getItem('hasJiraCredentials')){
            setHasJiraCredentials(localStorage.getItem('hasJiraCredentials') === 'True')
        }
    },[])
    useEffect(()=>{
        getFeatures()
    },[])

    const getFeatures = () => {
        sendRequest({url: "api/featurevoting"}, handleResponse)
    }

    const handleResponse = (data) => {
        if(data && data.length > 0){
            const featuresNotDone = data.filter(feature => feature.Status !== 'Resolved' && feature.Status !== 'To be Updated')
            setFeatures(featuresNotDone)
            const featuresDone = data.filter(feature => feature.Status === 'Resolved' || feature.Status === 'To be Updated')
            setImplementedFeatures(featuresDone)
            let date = null
            featuresDone.forEach(f => {
                if(f.ResolutionDate && (!date || f.ResolutionDate > date)){
                    date = f.ResolutionDate
                }
            })
            setLastFeatureDone(date)
        }
    }

    return (
        <Fragment>
            {isLoading && hasJiraCredentials && <LoadingSpinner/>}
               {hasJiraCredentials && <Fragment><MainHeader title="Requested Features" />
               <div className={classes.header}>
               <h2 className='h2'>Requested Features</h2>
               <div  ref={descRef} className={classes.buttons}>
                    <ButtonLOut onClick={() => setShowDescription(prevState => !prevState)}>How this page works</ButtonLOut>
                    <ButtonL onClick={()=>{navigate('/new-features/suggest-feature');}}>Suggest New Feature</ButtonL>
                    </div>
                    {showDescription && <MessageBox onClose={() => setShowDescription(false)} className={classes['features-description']}><p className={classes['features-description__1']}>
            Advanced Data is constantly on the lookout to make your workflow simple and more efficient!<br className={classes['wide-br']}/>
            <span>We develop our software by listening to you!</span></p>
            <p className={classes['features-description__2']}>Displayed is a list of features, that have been requested by users like you, that our clients would like to have implemented and added to our already amazing software.</p>
            <ol className={classes['features-description__3']}>
            <h4>Here is how this portal works:</h4>
            <li>Every user gets 3 votes which they can use to vote for the features requested and with that up the priority of having it implemented.</li>
            <li>Once a feature is done, the votes will be returned to you.</li> 
            <li>By clicking on the eye icon you can enable/disable the watch over a ticket.</li>
            <li>When the watch over a ticket is enabled, you will receive email notifications with the progress of the request</li></ol>
            <p>Do you have any other great ideas that you would like for us to implement? <Link to='/new-features/suggest-feature' onClick={() => setShowDescription(false)}>Click here</Link> to submit your request.
            </p></MessageBox>}
                </div>
        <section className={classes['features-page']}>
            {features.length > 0 && <FeaturesList features={features} lastFeatureDone={lastFeatureDone}/>}
        {implementedFeatures && implementedFeatures.length > 0 && <div className={classes['implemented-features']}>
            <h3 className={classes['implemented-features__title']}>Implemented Features</h3>
        {implementedFeatures.map((f, i) => <ImplementedFeature feature={f} key={i}/>)}
        </div>}
        </section>
        </Fragment>}
        {!hasJiraCredentials && <JiraCredentials/>}
        </Fragment>
    )
}

export default NewFeatures