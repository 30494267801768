import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import PageHeaders from "../../layout/PageHeaders";
import MainHeader from "../../layout/MainHeader";
import LoadingSpinner from "../../UI/LoadingSpinners/LoadingSpinner";
import { fetchRemindersList } from "../../../store/reminders-actions";
import RemindersList from "./RemindersList";
import useFetchSortedList from "../../../hooks/use-fetch-sorted-list";
import FilterReminders from "../../Filters/RemindersFilter/FilterReminders";

const COLUMNS = [
  { value: 1, name: "PledgeID" },
  { value: 2, name: "DonorID" },
  { value: 3, name: "Donor" },
  { value: 4, name: "Amount" },
  { value: 5, name: "Paid" },
  { value: 6, name: "Balance" },
  { value: 7, name: "Date" },
  { value: 8, name: "Campaign" },
  { value: 9, name: "Sub Campaign" },
];

const RemindersForm = () => {
    const navigate = useNavigate()
    
    const filter = useSelector((state) => state.filters.remindersFilterString);
    const remindersList = useSelector((state) => state.reminders.reminders);
    const loadedReminders = useSelector((state) => state.reminders.loadedReminders);
    const isLoading = useSelector((state) => state.reminders.isLoading);
    
    const [showFilter, setShowFilter] = useState();
    const { orderBy, desc, sortList, loadMore } = useFetchSortedList(
        fetchRemindersList,
        filter
    );

  const addReminderHandler = () => {
    navigate('new-reminder');
  };

  const toggleFilterHandler = () => {
    setShowFilter((prevState) => !prevState);
  };

  const showMoreRowsHandler = () => {
    loadMore(remindersList.length);
  };

  const sortListHandler = (column) => {
    sortList(column);
  };

  return (
    <Fragment>
      <MainHeader title="All Reminders" />
      {loadedReminders && (
        <PageHeaders
          onAdd={addReminderHandler}
          onOpenFilter={toggleFilterHandler}
          total={remindersList.length > 0 ? remindersList[0].TotalReminders : 0}
          title="Reminder"
          orderBy={orderBy}
          onChangeSort={sortListHandler}
          desc={desc}
          columns={COLUMNS}
        />
      )}
      {isLoading && <LoadingSpinner />}
      {showFilter && <FilterReminders onClose={toggleFilterHandler} />}
      <RemindersList
        reminders={remindersList}
        onLoadMore={showMoreRowsHandler}
        onSortList={sortListHandler}
      />
      {remindersList.length === 0 && loadedReminders && (
        <p className="list-no-found">No Reminders Found</p>
      )}
    </Fragment>
  );
};

export default RemindersForm;
