import { useEffect } from "react"
import CustomSelectFreeText from "../UI/Dropdowns/CustomSelectFreeText"
import { fetchReminderNames } from "../../store/hub-actions"
import { useDispatch, useSelector } from "react-redux"

const ReminderNames = ({onChange, name, invalid, className}) => {
    const dispatch = useDispatch()

    const names = useSelector((state) => state.hub.reminderNames)
    

    useEffect(()=>{
            if(!names){ 
            dispatch(fetchReminderNames())
        }
    },[names, dispatch])

    return (
        <CustomSelectFreeText options={names || []} label='Name' required onChange={onChange} className={className} selectedValue={name} invalid={invalid}/>
    )
}

export default ReminderNames