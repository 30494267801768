import { useState, useRef, useEffect } from 'react'

import classes from './DonorSearchBox.module.css'
import Search from '../../../../images/Search.svg'
import DonorSearchItem from './DonorSearchItem';
import LoadingSpinnerSearchBox from '../../LoadingSpinners/LoadingSpinnerSearchBox';

const DonorSearchBox = (props) => {
  const itemRef = useRef()

  const [inputValue, setInputValue] = useState('')
  const [isOptionsOpen, setIsOptionsOpen] = useState(false)
  const [cursor, setcursor] = useState(0)
  const [timerRunning, setTimerRunning] = useState() 
  const typingTimeout = useRef(null);



  const changeInputHandler = (e) => {
    const value = e.target.value
    if(value !== inputValue){
      if (typingTimeout.current) {
        clearTimeout(typingTimeout.current);
      }
      let valueForSearch = undefined
      if(value && value.length > 2) valueForSearch = value
      else if(value.length < 3 && (!value || inputValue.length > 2 )) valueForSearch = ''
      if(valueForSearch !== undefined){
        setTimerRunning(true)
        typingTimeout.current = setTimeout(() => {
          handleStopTyping(valueForSearch);
        }, 1000); 
      }
      setInputValue(value)
    }
    setIsOptionsOpen(true)
  }

  const handleStopTyping = (value) => {
    setTimerRunning(false)
    props.onChangeSearchbox(value)
  };

  const optionChanged = (index) => {
    if (props.list.length !== 0) {
      const value = props.list[index].value
      props.onSelect(value)
    }
  }
  const setSelectedThenCloseDropdown = (index) => {
    optionChanged(index)
    setIsOptionsOpen(false);
  };

  const handleKeyDown = (index) => (e) => {
    switch (e.key) {
      case " ":
      case "SpaceBar":
      case "Enter":
        e.preventDefault();
        setSelectedThenCloseDropdown(index);
        break;
      default:
        break;
    }
  };

  const inputKeyDownHandler = (e) => {
    if (e.keyCode === 38 && cursor > 0) {
      setcursor(prevState => prevState - 1)
    }
    else if (e.keyCode === 40 && cursor < props.list.length - 1) {
      setcursor(prevState => prevState + 1)

    }
    else if (e.key === 'Enter') {
      setSelectedThenCloseDropdown(cursor)
    }
  }

  useEffect(() => {
    var itemComponent = itemRef;
    if (itemComponent && itemComponent.current) {
      itemComponent.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      })
    }
  }, [cursor])

  

 

  const unFocuseHandler = (event) => {
    if (event.relatedTarget && event.relatedTarget.id && event.relatedTarget.id === "listItem") {
      return
    }
    //setIsOptionsOpen(false)
  }


  return (
    <div className={`${classes.search} ${props.className}`} onBlur={unFocuseHandler}>
      <img src={Search} alt='' />
      <input type="text" autoFocus={props.autoFocus} placeholder={props.placeholder} onChange={changeInputHandler} value={inputValue} onFocus={changeInputHandler} onKeyDown={inputKeyDownHandler} />
      {props.list.length > 0 && isOptionsOpen && <p className={classes['donors__results']}>{props.list.length} Results</p>}
      {isOptionsOpen && inputValue.length > 2 &&<ul
        className={classes.options}
        role="listbox"
        tabIndex={-1}
      >
        {props.loading && <LoadingSpinnerSearchBox/>}
        {!props.loading && props.list.length === 0 && !timerRunning && <p className={classes['no-results']}>No Results</p>}
        {props.list.map((option, index) => (
          <DonorSearchItem
            key={option.value}
            ref={cursor === index ? itemRef : null}
            onKeyDown={handleKeyDown(index)}
            onClick={() => {
              setSelectedThenCloseDropdown(index);
            }}
            active={cursor === index}
            option={option}
            scrollIntoView
          />
        ))}
      </ul>}
    </div>

  )
}

export default DonorSearchBox