
import ButtonL from '../Buttons/Buttons/ButtonL'
import GrayTextButton from '../Buttons/TextButtons/GrayTextButton'
import GreenTextButton from '../Buttons/TextButtons/GreenTextButton'
import classes from './FilterModal.module.css'
import MessageBox from './MessageBox'

const FilterModal = (props) => {

    return <MessageBox onClose={props.onClose} title='Add Filters' className={classes['filter-modal']}>
        {props.children}
        <div className={classes['filter-buttons']}>
        <GrayTextButton onClick={props.onClose} className={classes['filter-cancel']}>Cancel</GrayTextButton>
        <GreenTextButton onClick={props.onClear}>Clear All Filters</GreenTextButton>
        <ButtonL onClick={props.onApply}>Apply Filters</ButtonL>
        </div>
    </MessageBox>
}

export default FilterModal