import { useEffect, useRef, useState } from "react"
import CustomSelect from "../Dropdowns/CustomSelect"
import classes from './SortBy.module.css'
import Sort from '../../../images/Sort.svg'
import CheckboxS from "../Checkboxs/CheckboxS"
import useOutsideAlerter from "../../../hooks/use-outside-alerter"

const SortBy = (props) => {

    const sortRef = useRef()
    const {clickedOutside, resetState} = useOutsideAlerter(sortRef)
    
    const [showSort, setShowSort] = useState()

    const toggleShowSortHandler = (e) => {
        setShowSort(prevState => !prevState)
    }

    const listClickedHandler = (e) => {
        e.stopPropagation()
    }

    useEffect(()=>{
        if(clickedOutside){
            setShowSort(false)
            resetState()
        }
    },[clickedOutside])

    const changeSortByHandler = (id) => {
        var col = props.columns.find(c => c.value === id)
        var colName = ""
        if(col){
            colName = col.name
        }
        if(colName.toLowerCase().includes('date')){
            props.onChange(id, true)
        }
        else{
            props.onChange(id, false)
        }
    }

    return (
        <div ref={sortRef} className={classes.sort}>
        <div className={classes['sort-button']} onClick={toggleShowSortHandler}><img src={Sort} alt=''/>
        {showSort && <div className={classes['sort-popup']} onClick={listClickedHandler}><CustomSelect
        label='Sort by'
        options={props.columns} 
        selectedValue={props.selectedColumn}
        onChange={changeSortByHandler}
        className={classes.wrapper}
        
        />
       <CheckboxS 
       checked={props.desc}
       onChange={() => props.onChange(props.selectedColumn, !props.desc)}
       onBlur={()=>{}}
       label='Descending'
       id='sort'
       />
        </div>}
        </div>
        </div>
    )
}

export default SortBy