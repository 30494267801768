import { useDispatch, useSelector } from "react-redux"
import CustomSelectFreeText from "../UI/Dropdowns/CustomSelectFreeText"
import { useEffect } from "react"
import { fetchReminderTypes } from "../../store/hub-actions"

const ReminderTypes = ({type, onChange, className}) => {
    const dispatch = useDispatch()
    const types = useSelector((state) => state.hub.reminderTypes)

    useEffect(()=>{
        if(!types){
            dispatch(fetchReminderTypes())
        }
    },[types, dispatch])

    return(
        <CustomSelectFreeText selectedValue={type} options={types || []} label='Type' onChange={onChange} className={className} />

    )
}

export default ReminderTypes