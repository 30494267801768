import { useCallback, useState } from "react";
import { useSelector } from "react-redux";

const useHttp = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const baseUrl = useSelector((state) => state.auth.url);

    const sendRequest = useCallback(async (requestConfig, applyData) => {
        setIsLoading(true);
        setError(null);

        const token = localStorage.getItem('token');
        if (!token) {
            setError("Authentication token is missing.");
            setIsLoading(false);
            return;
        }

        const requestUrl = `${baseUrl}${requestConfig.url}`;
        const headers = requestConfig.headers || {};
        const requestHeaders = { ...headers, 'Authorization': `Bearer ${token}` };

        try {
            const response = await fetch(requestUrl, {
                method: requestConfig.method || 'GET',
                headers: requestHeaders,
                body: requestConfig.body ? JSON.stringify(requestConfig.body) : null
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.Message || "Something went wrong!");
            }

            const data = await response.json();
            applyData(data);
        } catch (err) {
            setError(err.message || "Something went wrong!");
        } finally {
            setIsLoading(false);
        }
    }, [baseUrl]);

    return { isLoading, error, sendRequest };
};

export default useHttp;
