import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { uiActions } from '../../../store/ui-slice';
import SidePopup from './SidePopup';

const SidePopupMessage = () => {
    const sidePopup = useSelector((state) => state.ui.sidePopup);
    const dispatch = useDispatch();

    const closePopup = useCallback(() => {
        dispatch(uiActions.showSidePopup(null));
    }, [dispatch]);

    useEffect(() => {
        if (sidePopup) {
            const timer = setTimeout(closePopup, 4500);
            return () => clearTimeout(timer);
        }
    }, [sidePopup, closePopup]);

    if (!sidePopup) return null;

    return (
        <SidePopup 
            status={sidePopup.status} 
            header={sidePopup.header} 
            message={sidePopup.message} 
            onClose={closePopup}
        />
    );
};

export default SidePopupMessage;
