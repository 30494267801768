import { useState, useEffect } from "react"
import { useSelector } from "react-redux"

import MultiSelect from "../../UI/Dropdowns/MultiSelect"

const FilterByCampaign = (props) => {

    //#region hooks
    const [campaigns, setCampaigns] = useState()
    const [selectedIds, setSelectedIds] = useState([])

    const campaignsList = useSelector((state) => state.campaigns.campaigns)

    useEffect(() => {
        if (campaignsList.length !== 0) {
            var camaigns = campaignsList.map(campaign => {
               return {id: campaign.CampaignID, label: campaign.Name}
          })
            setCampaigns(camaigns)
        }
    }, [campaignsList])
    useEffect(() => {
        var ids = []
        if(props.initialCampaignIds && campaigns && campaigns.length > 0){
            props.initialCampaignIds.forEach(id => {
               const index = campaignsList.findIndex(campaign => campaign.CampaignID === id)
               if(index !== undefined){
                ids = [...ids, campaigns[index]]
               }
            })
            setSelectedIds(ids)
        }
        else{
            setSelectedIds([])
        }
    }, [props.initialCampaignIds, campaigns, campaignsList])

    //#endregion
    
    //#region event handlers
    const toggleOption = ({ id }) => {
        var newArray = [...selectedIds]
        if (newArray.some(item => item.id === id)) {
            newArray = newArray.filter(item => item.id !== id)
        } else {
            const item = campaigns.find(item => item.id === id)
            newArray.push(item)
        }
        setSelectedIds(newArray)

        const ids = newArray.map(item => item.id)
        props.onChange(ids)
    }

    const resetSelectedIdsHandler = () => {
        props.onChange([])
        setSelectedIds([])
    }

    //#endregion

    return (
        <MultiSelect label='Campaigns' options={campaigns} selected={selectedIds} toggleOption={toggleOption} clearFilter={resetSelectedIdsHandler}/>
    )
}
export default FilterByCampaign