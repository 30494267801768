
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPaymentTypesList } from '../store/paymentTypes-actions';
import { fetchCampaignsList, fetchSubCampaignsList } from '../store/campaigns-actions'
import { fetchOrganizationsList } from '../store/organizations-actions'
import { fetchDonorsList } from '../store/donors-actions'
import { fetchPledgesList, fetchRecurringPledgesList } from '../store/pledges-actions';
import { fetchSolicitorsList } from '../store/solicitors-actions'
import { fetchAccountsList } from '../store/accounts-actions';
import { fetchShulsList } from '../store/shuls-actions';
import { fetchNeededSettings } from '../store/settings-actions';
import { fetchRecurringPaymentsList } from '../store/payments-actions';
import { fetchStudentsList } from '../store/students-actions';
import { fetchLocationsList } from '../store/locations-actions';

const useFetchData = () => {
  const dispatch = useDispatch();

  const campaigns = useSelector((state) => state.campaigns.campaigns)
  const subCampaigns = useSelector((state) => state.campaigns.subCampaigns)
  const organizations = useSelector((state) => state.organizations.organizations)
  const locations = useSelector((state) => state.locations.locations)
  const donors = useSelector((state) => state.donors.donors)
  const pledges = useSelector((state) => state.pledges.pledges)
  const recurringPayments = useSelector((state) => state.payments.recurringPayments)
  const recurringPledges = useSelector((state) => state.pledges.recurringPledges)
  const loadedPledges = useSelector((state) => state.pledges.loadedPledges)
  const loadedRecurringPayments = useSelector((state) => state.payments.loadedRecurringPayments)
  const loadedRecurringPledges = useSelector((state) => state.pledges.loadedRecurringPledges)
  const solicitors = useSelector((state) => state.solicitors.solicitors)
  const paymentTypes = useSelector((state) => state.paymentTypes.paymentTypes)
  const accounts = useSelector((state) => state.accounts.accounts)
  const shuls = useSelector((state) => state.shuls.shuls)
  const loggedIn = useSelector((state) => state.auth.isLoggedIn)
  const parentLoggedIn = useSelector((state) => state.auth.parentLoggedIn)
  const hubUserLoggedIn = useSelector((state) => state.auth.hubUserLoggedIn)
  const settingsLoaded = useSelector((state) => state.settings.settingsLoaded)
  const loadedStudents = useSelector((state) => state.students.loadedStudents)
  const loadedDonors = useSelector((state) => state.donors.loadedDonors)


  useEffect(() => {
    
  }, [dispatch]);

  useEffect(() => {
    if (campaigns.length === 0 && (loggedIn || hubUserLoggedIn)) {
      dispatch(fetchCampaignsList())
    }
  }, [dispatch, campaigns.length, loggedIn, hubUserLoggedIn])
  useEffect(() => {
    if (subCampaigns.length === 0 && loggedIn && ! window.location.href.includes('-app')) {
      dispatch(fetchSubCampaignsList())
    }
  }, [dispatch, subCampaigns.length, loggedIn])
  useEffect(() => {
    if (organizations.length === 0 && loggedIn && ! window.location.href.includes('-app')) {
      dispatch(fetchOrganizationsList())
    }
  }, [dispatch, organizations.length, loggedIn])
  useEffect(() => {
    if (locations.length === 0 && loggedIn && ! window.location.href.includes('-app')) {
      dispatch(fetchLocationsList())
    }
  }, [dispatch, locations.length, loggedIn])
  useEffect(() => {
    if (donors.length === 0 && !loadedDonors && loggedIn && ! window.location.href.includes('-app')) {
      dispatch(fetchDonorsList(50))
    }
  }, [dispatch, donors.length, loggedIn, loadedDonors])
  useEffect(() => {
    if (pledges.length === 0 && !loadedPledges && loggedIn && ! window.location.href.includes('-app')) {
      dispatch(fetchPledgesList(50))
    }
  }, [dispatch, pledges.length, loggedIn, loadedPledges])
  useEffect(() => {
    if (recurringPayments.length === 0 && !loadedRecurringPayments && loggedIn && ! window.location.href.includes('-app')) {
      dispatch(fetchRecurringPaymentsList(50))
    }
  }, [dispatch, recurringPayments.length, loggedIn, loadedRecurringPayments])
  useEffect(() => {
    if (recurringPledges.length === 0 && !loadedRecurringPledges && loggedIn && ! window.location.href.includes('-app')) {
      dispatch(fetchRecurringPledgesList(50))
    }
  }, [dispatch, recurringPledges.length, loggedIn, loadedRecurringPledges])
  useEffect(() => {
    if (solicitors.length === 0 && loggedIn && ! window.location.href.includes('-app')) {
      dispatch(fetchSolicitorsList())
    }
  }, [dispatch, solicitors.length, loggedIn])
  useEffect(() => {
    if (paymentTypes.length === 0 && (loggedIn || parentLoggedIn) && ! window.location.href.includes('-app')) {
      dispatch(fetchPaymentTypesList())
    }
  }, [dispatch, paymentTypes.length, loggedIn, parentLoggedIn])
  useEffect(() => {
    if (accounts.length === 0 && loggedIn && ! window.location.href.includes('-app')) {
      dispatch(fetchAccountsList())
    }
  }, [dispatch, accounts.length, loggedIn])
  useEffect(() => {
    if (shuls.length === 0 && loggedIn && ! window.location.href.includes('-app')) {
      dispatch(fetchShulsList())
    }
  }, [dispatch, shuls.length, loggedIn])
  useEffect(()=>{
    if(!settingsLoaded && (loggedIn) && ! window.location.href.includes('-app')){
      dispatch(fetchNeededSettings())
    }
  },[dispatch, settingsLoaded, loggedIn])
  useEffect(()=>{
    if(parentLoggedIn && !loadedStudents && ! window.location.href.includes('-app')){
      dispatch(fetchStudentsList())
    }
  },[parentLoggedIn, loadedStudents, dispatch])
  useEffect(()=>{
    if(parentLoggedIn && !loadedStudents && ! window.location.href.includes('-app')){
      dispatch(fetchStudentsList())
    }
  },[parentLoggedIn, loadedStudents, dispatch])

};

export default useFetchData;
