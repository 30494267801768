
import { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import classes from './DonorsInfo.module.css'
import Phone from '../../../images/Call.svg'
import Location from '../../../images/Location.svg'
import Message from '../../../images/Message.svg'
import CustomSelect from '../../UI/Dropdowns/CustomSelect'
import Arrow from '../../../images/Arrow - Down small.svg'
import formatAddress from '../../../functions/formatAddress'
import formatPhone from '../../../functions/formatPhone'
import GreenTextButton from '../../UI/Buttons/TextButtons/GreenTextButton'
import { donorsActions } from '../../../store/donors-slice'
import { tendersActions } from '../../../store/tenders-slice'
import { pledgesActions } from '../../../store/pledges-slice'
import PinkTextButton from '../../UI/Buttons/TextButtons/PinkTextButton'

const DonorsInfo = ({couldChangeAddress = true, addressId, email, onChangeAddress, onChangeEmail, canRemove, hideDetails, onViewDetails}) => {
    const dispatch = useDispatch()

    //#region hooks
    const [address, setAddress] = useState()
    const [formattedAddress, setFormattedAddress] = useState()
    const [addresses, setAddresses] = useState([])
    const [showAddresses, setShowAddresses] = useState()
    const [selectedEmail, setSelectedEmail] = useState('')
    const [emails, setEmails] = useState([])
    const [showEmails, setShowEmails] = useState()
    const [formattedPhone, setFormattedPhone] = useState()


    const addressesList = useSelector((state) => state.donors.addresses)
    const emailsList = useSelector((state) => state.donors.emails)
    const selectedDonor = useSelector((state) => state.donors.selectedDonor)
    const selectedPayment = useSelector((state) => state.payments.selectedPayment)

    //#endregion

    //#region use effects
    useEffect(() => {
        if(addressesList.length !== 0){
        const addresses = addressesList.map(address => {
            var formattedAddress = formatAddress(address)
            return { value: address.DonorAddressId, name: formattedAddress}
        })
        setAddresses(addresses)
        }
    }, [addressesList])

    useEffect(()=>{
        var address
        if(addressId === 0 && Object.keys(selectedPayment).length !== 0){
            address = {DonorAddressId: 0, Address: selectedPayment.Address, City: selectedPayment.City, State: selectedPayment.State, Zip: selectedPayment.Zip}
            setAddress(address)
            setFormattedAddress(formatAddress(address))
        }
        if (addressId && addressesList.length !== 0){
            address = addressesList.find(address => address.DonorAddressId === addressId)
            if(address){
                setAddress(address)
                setFormattedAddress(formatAddress(address))
            }
        }
        if(!couldChangeAddress){
            address = {DonorAddressId: 0, Address: selectedDonor.Address, City: selectedDonor.City, State: selectedDonor.State, Zip: selectedDonor.Zip}
            setAddress(address)
            setFormattedAddress(formatAddress(address))
        }
    },[addressId, addressesList, couldChangeAddress, selectedDonor, selectedPayment])

    useEffect(()=>{
        if(emailsList.length !== 0){
            const emails = emailsList.map(email => {
                return {value: email.Contact, name: email.Contact}
            })
            setEmails(emails)
        }
    },[emailsList])

    useEffect(()=>{
        if (email && emailsList.length !== 0){
            const email = emailsList.find(email => email.Contact === email)
            if(email){
                setSelectedEmail(email.Contact)
            }
        } 
    },[email, emailsList])

    useEffect(()=>{
        const phone = formatPhone(selectedDonor.PhoneMobile)
        setFormattedPhone(phone)
    },[selectedDonor])

    useEffect(()=>{
        if(!couldChangeAddress){
            setSelectedEmail(selectedDonor.Email)
        }   
    },[selectedDonor, couldChangeAddress])

    //#endregion

    //#region event handlers
    const toggleShowAddressesHandler = () => {
        setShowAddresses(prevState => !prevState)
    }

    const toggleShowEmailsHandler = () => {
        setShowEmails(prevState => !prevState)
    }

    const changeAddressHandler = (id) => {
        onChangeAddress(id)
        setShowAddresses(false)
    }

    const changeEmailHandler = (id) => {
        onChangeEmail(id)
        setShowEmails(false)
    }

    const removeDonorHandler = () => {
        dispatch(donorsActions.resetDonorsInfo())
        dispatch(tendersActions.resetTenderList())
        dispatch(pledgesActions.resetDonorsInfo())
    }

    //#endregion
    
    return (
        <Fragment>{selectedDonor && <Fragment>
            <div className={classes.buttons}>
                {canRemove && <PinkTextButton  type='button' onClick={removeDonorHandler}>Remove</PinkTextButton>}
                {selectedDonor.HasDonorPermission && !hideDetails && <GreenTextButton className={classes['btn-view-details']} type='button' onClick={onViewDetails}>View Details</GreenTextButton>}
            </div>
            <h3 className={classes['donor-name']}>{`${selectedDonor.FirstName} ${selectedDonor.LastName}`}</h3>
            <div className={classes['donor-phone']}><img src={Phone} alt=''/><p>{formattedPhone}</p></div>
            <div className={classes['donor-address']}><img src={Location} alt=''/> {address && <p>{formattedAddress}</p>}
            {(addresses.length > 1 || (addresses.length === 1 && selectedPayment.DonorsAddressID === 0)) && couldChangeAddress && <div className={classes['additional-addresses']} onClick={toggleShowAddressesHandler}><p>Additional Addresses</p><img src={Arrow} alt=''/></div>}</div>
            {showAddresses && <CustomSelect options={addresses} label='Addresses' onChange={changeAddressHandler} className={classes['additional-addresses__list']} selectedValue={addressId}/>}
            <div className={classes['donor-email']}><img src={Message} alt=''/><p>{selectedEmail && selectedEmail}</p>
            {(emails.length > 1 || (emails.length === 1 && selectedEmail === '')) && couldChangeAddress && <div className={classes['additional-addresses']} onClick={toggleShowEmailsHandler}><p>Additional Emails</p><img src={Arrow} alt=''/></div>}</div>
            {showEmails && <CustomSelect options={emails} label='Emails' onChange={changeEmailHandler} className={classes['additional-addresses__list']} selectedValue={selectedEmail}/>}
        </Fragment>}
        </Fragment>
    )
}

export default DonorsInfo