import { useEffect, useState, useRef, useLayoutEffect } from 'react';
import classes from './TextField.module.css';

const TextField = (props) => {
    const textareaRef = useRef(null);

    const handleChange = (e) => {
        // Pass the updated value back to the parent through onChange
        props.onChange(e.target.value);
      };
    
      useEffect(() => {
        if (textareaRef.current) {
          textareaRef.current.style.height = 'auto'; // Reset height before calculating new height
          textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
      }, [props.value]);
    
    
    return (

        <div
            className={`${classes['text-area__wrapper']} ${props.invalid && classes['invalid-input']} ${props.className}`}>
            <label>
                {props.label} {props.required && <span className={classes['required-input']}>*</span>}
            </label>
            <textarea
                id={props.id}
                ref={textareaRef}
                value={props.value} // Value controlled by the parent
                onChange={handleChange}
                rows={1}  // Initial row size
                className={classes.textarea}
                autoFocus={props.autoFocus}
                placeholder={props.placeholder}
                readOnly={props.readOnly}
            />
            {props.children}
        </div>
    );
};


export default TextField;
