
import { forwardRef } from 'react'
import classes from './Input.module.css'

const Input = forwardRef(({label, value, onChange, children, required, invalid, className, readOnly, inputClassName, ...props }, ref) => {

    const changeInputHandler = (e) => {
        onChange(e.target.value)
    }

    return (
        <div className={`${classes['form-input__container']} ${invalid && classes['invalid-input']} ${className}`} ref={ref}>
        <div className={`${classes['form-group']} ${readOnly ? classes['readonly'] : ''}`}>
            <label>{label} {required && <span className={classes['required-input']}>*</span>}</label>
            <input className={inputClassName}
                {...props}
                onChange={changeInputHandler}
                value={value || ''}
                readOnly={readOnly}
                autoComplete={props.autoComplete ? props.autoComplete : 'on'}
              >
            </input>
            {children}
            </div>
            {invalid && <p>{invalid}</p>}
        </div>
    )
})

export default Input