import { paymentsActions } from './payments-slice'
import { uiActions } from './ui-slice';
import store from './index'
import { authActions } from './auth-slice';


export const fetchPaymentsList = (amountOfRows, orderBy = 0, desc = true, filter = '') => {
    return async (dispatch) => {
        const fetchData = async () => {
            dispatch(paymentsActions.setIsLoading(true))
            if(amountOfRows === 50){
                dispatch(paymentsActions.loadPayments({payments: []}))
            }
            if(!localStorage.getItem('token') || localStorage.getItem('token') === ''){
                return []
            }
            var bearer = 'Bearer ' + localStorage.getItem('token');
            var url = store.getState().auth.url
             url =  url + 'api/payments'
                url = url + `?rows=${amountOfRows >=50 ? amountOfRows : '50'}&orderBy=${orderBy}&desc=${desc}${filter}`
            const response = await fetch(url,{
                headers:{
                    'Authorization': bearer,
                }
            });

            if (!response.ok) {
                if(response.status === 401){ dispatch(authActions.logout()) }
                else{ throw new Error('Could not fetch payments list.') }
            }
            const data = await response.json()
            return data;
        };

        try {
            const paymentsData = await fetchData()
            dispatch(paymentsActions.loadPayments({ payments: paymentsData || [] }))
            dispatch(paymentsActions.setIsLoading(false))

        } catch (error) {
            dispatch(uiActions.showErrorMessage(error.message))
        }
    };
};



export const fetchPaymentsForDonor = (donorId) => {
    return async (dispatch) => {
        const fetchData = async () => {
            var bearer = 'Bearer ' + localStorage.getItem('token');
            const url = store.getState().auth.url
            const response = await fetch(url + `api/payments?rows=1000&donors[0]=${donorId}`,{
                headers:{
                    'Authorization': bearer,
                }
            });

            if (!response.ok) {
                if(response.status === 401){ dispatch(authActions.logout()) }
                else{ throw new Error('Could not fetch payments list.') }
            }
            const data = await response.json()
            return data;
        };

        try {
            const paymentsData = await fetchData()
            dispatch(paymentsActions.loadPaymentsForDonor({ payments: paymentsData || [] }))

        } catch (error) {
            dispatch(uiActions.showErrorMessage(error.message))
        }
    };
};

export const fetchPaymentsForPledge = (pledgeId) => {
    return async (dispatch) => {
        const fetchData = async () => {
            var bearer = 'Bearer ' + localStorage.getItem('token');
            const url = store.getState().auth.url
            const response = await fetch(url + `api/paymentsforpledge/${pledgeId}`,{
                headers:{
                    'Authorization': bearer,
                }
            });

            if (!response.ok) {
                if(response.status === 401){ dispatch(authActions.logout()) }
                else{ throw new Error('Could not fetch payments for pledge.') }
            }
            const data = await response.json()
            return data;
        };

        try {
            const paymentsData = await fetchData()
            dispatch(paymentsActions.loadPaymentsForPledge({ payments: paymentsData || [] }))

        } catch (error) {
            dispatch(uiActions.showErrorMessage(error.message))
        }
    };
};

export const setSelectedPaymentToNull = () => {
    return (dispatch) => {
        dispatch(paymentsActions.resetSelectedPayment())
    }
}

export const fetchRecurringPaymentsList = (amountOfRows, orderBy = 20, desc = true, filter = '') => {
    return async (dispatch) => {
        const fetchData = async () => {
            dispatch(paymentsActions.setRecurringIsLoading(true))
            if(amountOfRows === 50){
                dispatch(paymentsActions.loadRecurringPayments({ payments: [] }))
            }
            var bearer = 'Bearer ' + localStorage.getItem('token');
            var url = store.getState().auth.url
             url =  url + 'api/recurringpayments'
                url = url + `?rows=${amountOfRows >=50 ? amountOfRows : '50'}&orderBy=${orderBy}&desc=${desc}${filter}`
            const response = await fetch(url,{
                headers:{
                    'Authorization': bearer,
                }
            });

            if (!response.ok) {
                if(response.status === 401){ dispatch(authActions.logout()) }
                else{ throw new Error('Could not fetch recurring payments list.') }
            }
            const data = await response.json()
            return data;
        };

        try {
            const paymentsData = await fetchData()
            dispatch(paymentsActions.loadRecurringPayments({ payments: paymentsData || [] }))
            dispatch(paymentsActions.setRecurringIsLoading(false))

        } catch (error) {
            dispatch(uiActions.showErrorMessage(error.message))
        }
    };
};

export const fetchRecurringPaymentDetails = (recPaymentId) => {
    return async (dispatch) => {
        const fetchData = async () => {
            var bearer = 'Bearer ' + localStorage.getItem('token');
            var url = store.getState().auth.url
            const response = await fetch(url + `api/recurringpaymentdetails/${recPaymentId}`,{
                headers:{
                    'Authorization': bearer,
                }
            });

            if (!response.ok) {
                if(response.status === 401){ dispatch(authActions.logout()) }
                else{ throw new Error('Could not fetch recurring payment details.') }
            }
            const data = await response.json()
            return data;
        };

        try {
            const paymentsData = await fetchData()
            dispatch(paymentsActions.setSelectedRecurringPayment(paymentsData))

        } catch (error) {
            dispatch(uiActions.showErrorMessage(error.message))
        }
    };
};
