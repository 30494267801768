
import classes from './RemindersList.module.css'
import ListItem from '../../UI/Others/ListItem'
import { formatDate, formatDateWithTime } from '../../../functions/Dates/formatDate'
import { useNavigate } from 'react-router-dom'
import Badge from '../../UI/Others/Badge'

const getStatusName = (status) => {
    switch(status){
        case 0:
            return 'Open'
        case 1:
            return 'Complete'
        default:
            return 'Canceled'
    }
}

const Reminder = (props) => {
    const navigate = useNavigate()

    const showReminderEditHandler = () => {
        navigate(`reminder-details/${props.ReminderID}`)
    }
        
    const formattedDate = formatDate(props.Date)

    const statusName = getStatusName(props.Status)
    return (
        <ListItem className={classes.reminder}>
            <p className={classes.id}>{props.ReminderID}</p>
            <p className={classes.donorid}>{props.ID}</p>
            <p className={classes.donor}>{props.FirstName} {props.LastName}</p>
            <p className={classes.name}>{props.Name}</p>
            <p className={classes.type}>{props.Type}</p>
            <p className={classes.date}>{formattedDate}</p>
            <Badge status={statusName} className={classes.status}>{statusName}</Badge>
            <p className={classes.details} onClick={showReminderEditHandler}>View Details</p>
        </ListItem>
    )

}

export default Reminder