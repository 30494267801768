import { Fragment } from 'react'

import classes from './FixedButtonsBar.module.css'

const FixedButtonsBar = (props) => {

    return (
        <Fragment>
            <div className={`${classes['fixed-bar']} ${props.className}`}>{props.children}</div>
        </Fragment>
    )
}

export default FixedButtonsBar