import { authActions } from './auth-slice';
import { donorsActions } from './donors-slice'
import store from './index' 
import { uiActions } from './ui-slice';

export const fetchDonorsList = (amountOfRows, orderBy = 12, desc = false, filter = '') => {
    return async (dispatch) => {
        const fetchData = async () => {
            dispatch(donorsActions.setIsLoading(true))
            var bearer = 'Bearer ' + localStorage.getItem('token');
            let url = store.getState().auth.url
            url =  url + 'api/donors'
            url = url + `?rows=${amountOfRows >=50 ? amountOfRows : '50'}&orderBy=${orderBy}&desc=${desc}${filter}`
        
            const response = await fetch(url, {
                headers:{
                    'Authorization': bearer,
                }
            });

            if (!response.ok) {
                if(response.status === 401){ dispatch(authActions.logout()) }
                else{ throw new Error('Could not fetch donors list.') }
            }
            const data = await response.json()
            return data;
        };

        try {
            const donorsData = await fetchData()
            dispatch(donorsActions.loadDonors({ donors: donorsData || [] }))
        } catch (error) {

        }
        finally {
            dispatch(donorsActions.setIsLoading(false))

        }
    };
};
export const fetchSingleDonorNotForList = (donorId) => { //example: for payment details - the donor doesn't have to be in list
    return async (dispatch) => {
        const fetchData = async () => {
            dispatch(donorsActions.setIsLoading(true))
            var bearer = 'Bearer ' + localStorage.getItem('token');
            const url = store.getState().auth.url
            const response = await fetch(`${url}api/donors?rows=1&orderBy=0&desc=true&donorId=${donorId}`, {
                headers:{
                    'Authorization': bearer,
                }
            });

            if (!response.ok) {
                if(response.status === 401){ dispatch(authActions.logout()) }
                else{ throw new Error('Could not fetch donors details.') }
            }
            const data = await response.json()
            return data;
        };

        try {
            const donorsData = await fetchData()
            console.log(donorsData)
            dispatch(donorsActions.setSelectedDonor(donorsData && donorsData.length > 0 ? donorsData[0] : {}))
        } catch (error) {
            uiActions.showErrorMessage(error.message)
        }
        finally {
            dispatch(donorsActions.setIsLoading(false))

        }
    };
};

export const fetchDonorsAddressesList = (donorId) => {
    return async (dispatch) => {
        const fetchData = async () => {
            dispatch(donorsActions.setIsLoading(true))
            var bearer = 'Bearer ' + localStorage.getItem('token');
            const url = store.getState().auth.url
            const response = await fetch(`${url}api/donorsaddresses/${donorId}`,{
                headers:{
                    'Authorization': bearer,
                }
            });

            if (!response.ok) {
                if(response.status === 401){ dispatch(authActions.logout()) }
                else{ throw new Error('Could not fetch donors addresses list.') }
            }
            const data = await response.json()
            return data;
        };

        try {
            const addressesData = await fetchData()
            dispatch(donorsActions.loadAddresses({ addresses: addressesData || [] }))
        } catch (error) {
            uiActions.showErrorMessage(error.message)
        }
        finally {
            dispatch(donorsActions.setIsLoading(false))

        }
    };
};
export const fetchDonorsContactsList = (donorId) => {
    return async (dispatch) => {
        const fetchData = async () => {
            dispatch(donorsActions.setIsLoading(true))
            var bearer = 'Bearer ' + localStorage.getItem('token');
            const url = store.getState().auth.url
            const response = await fetch(`${url }api/donorscontacts/${donorId}`,{
                headers:{
                    'Authorization': bearer,
                }
            });

            if (!response.ok) {
                if(response.status === 401){ dispatch(authActions.logout()) }
                else{ throw new Error('Could not fetch donors contacts list.') }
            }
            const data = await response.json()
            return data;
        };

        try {
            const contactsData = await fetchData()
            dispatch(donorsActions.loadContacts({ contacts: contactsData || [] }))
        } catch (error) {
            dispatch(uiActions.showErrorMessage(error.message))
        }
        finally {
            dispatch(donorsActions.setIsLoading(false))

        }
    };
};

export const setDonorsIsLoading = (value) => {
    return (dispatch) => {
        dispatch(donorsActions.setIsLoading(value))
    }
}

