import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    donors: [],
    loadedDonors: false,
    quantity: 50,
    addresses: [],
    contacts: [],
    phoneHomes: [],
    phoneMobiles: [],
    emails: [],
    selectedDonor: {},
    isLoading: false,
    donorsForFilter: [],
    filteredDonorsList: [],
    donorReminders: []
}

const donorsSlice = createSlice({
    name: "donors",
    initialState,
    reducers: {
        loadDonors(state, action) {
            state.loadedDonors = true
            state.donors = action.payload.donors;
            state.quantity = action.payload.donors.length
            if(state.selectedDonor && Object.keys(state.selectedDonor).length > 0){ //if reloaded the page after adding payment etc. it should also update the selected donor
                let donor = action.payload.donors.find(d => d.DonorsID === state.selectedDonor.DonorsID)
                if(donor){
                    state.selectedDonor = donor
                }
            }
        },
        showLessRows(state){
            state.donors = state.donors.slice(0, state.quantity - 51)
            state.quantity = state.quantity - 50
        },
        setIsLoading(state, action) {
            state.isLoading = action.payload
        },
        setSelectedDonor(state, action) {
            if (action.payload) {
                const id = action.payload
                    const selectedDonor = state.donors.find(donor => donor[Object.keys(donor)[0]] === id)
                if (selectedDonor) {
                    state.selectedDonor = selectedDonor
                }
                else if(typeof action.payload === 'object'){
                    state.selectedDonor = action.payload
                }
            }
        },
        loadAddresses(state, action) {
            state.addresses = action.payload.addresses;

        },
        loadContacts(state, action) {
            state.contacts = action.payload.contacts
            const emails = action.payload.contacts.filter(contact => contact.Type === 3)
            if(emails) state.emails = emails

            const phoneHomes = action.payload.contacts.filter(contact => contact.Type === 0)
            if(phoneHomes) state.phoneHomes = phoneHomes

            const phoneMobiles = action.payload.contacts.filter(contact => contact.Type === 2)
            if(phoneMobiles) state.phoneMobiles = phoneMobiles
        },
        loadDonorReminders(state, action){
            state.donorReminders = action.payload
        },
        resetAddressesList(state) {
            state.addresses = []
        },
        resetEmailsList(state) {
            state.emails = []
        },
        resetSelectedDonor(state) {
            state.selectedDonor = {}
            state.donorReminders = []
        },
        resetDonorsInfo(state) {
            state.selectedDonor = {}
            state.addresses = []
            state.contacts = []
            state.emails = []
            state.phoneHomes = []
            state.phoneMobiles = []
            state.donorReminders = []
        },
        resetState:() => initialState,
    },
});

export const donorsActions = donorsSlice.actions;

export default donorsSlice;
