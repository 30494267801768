import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import FilterByDonor from "../PaymentsFilter/FilterByDonor"
import { filtersActions } from "../../../store/filters-slice"
import FilterByCampaign from "../PaymentsFilter/FilterByCampaign"
import classes from '../PaymentsFilter/FilterPayments.module.css'
import FilterModal from "../../UI/Modals/FilterModal"
import Input from "../../UI/Inputs/Input"
import ReminderNames from "../../Reminders/ReminderNames"
import IdsInput from "../../UI/Inputs/IdsInputs"
import ReminderTypes from "../../Reminders/ReminderTypes"
import CustomSelect from "../../UI/Dropdowns/CustomSelect"

const REMINDER_STATUSES = [{value: -1, name: ''}, {value: 0, name: 'Open'}, {value: 1, name: 'Complete'}, {value: 2, name: 'Canceled'}]

const setUpListFilter = (name, ids) => {
    const params = new URLSearchParams();
    ids.forEach((id, index) => params.append(`${name}[${index}]`, id));
    return params.toString();
};


const FilterReminders = (props) => {

    const dispatch = useDispatch()

    const filter = useSelector((state) => state.filters.remindersFilter)

    const [filters, setFilters] = useState(filter)
    const [dateRangeInvalid, setDateRangeInvalid] = useState()
    

    useEffect(() => {
       if(filter){
        setFilters(filter)
       }
    }, [filter])
   
   
    const changeDateFromHandler = (value) => {
        if (filters.DateTo) {
            if (new Date(value) > new Date(filters.DateTo)) {
                setDateRangeInvalid(true)
            }
            else {
                setDateRangeInvalid(false)
            }
        }

        setFilters(prevState => ({...prevState, DateFrom: value}))
    }
    const changeDateToHandler = (value) => {
        if (filters.DateFrom) {
            if (new Date(filters.DateFrom) > new Date(value)) {
                setDateRangeInvalid(true);
            } else {
                setDateRangeInvalid(false);
            }
            
        }
        setFilters(prevState => ({...prevState, DateTo: value}))
    }

    const searchFilterHandler = (event) => {
        if(event){
            event.preventDefault()
        }
        if (new Date(filters.DateFrom) > new Date(filters.DateTo)) {
            setDateRangeInvalid(true)
            return;
        }
        var filter = ''
        if (filters.DateFrom) {
            filter = filter + '&dateFrom=' + filters.DateFrom
        }
        if (filters.DateTo) {
            filter = filter + '&dateTo=' + filters.DateTo
        }
        if (filters.ReminderIds && filters.ReminderIds.length > 0) {
            const ids = filters.ReminderIds.split(',').map(item => parseInt(item.trim()));
            filter += setUpListFilter('reminderIds', ids)
        }
        if (filters.Donors && filters.Donors.length > 0) {
            let ids = filters.Donors.map(d => d.id)
            filter = setUpListFilter('donors', ids)
        }
        if (filters.Campaigns && filters.Campaigns.length > 0) {
            filter = filter + setUpListFilter('campaigns', filters.Campaigns)
        }
        if (filters.Name) {
            filter = filter + '&name=' + filters.Name
        }
        if (filters.Type) {
            filter = filter + '&type=' + filters.Type
        }
        if (filters.Description) {
            filter = filter + '&description=' + filters.Description
        }
        if(filters.Status > -1){
            filter = filter + '&status=' + filters.Status
        }
        console.log(filter)
        dispatch(filtersActions.setRemindersFilter(filters))
        dispatch(filtersActions.setRemindersFilterString(filter))
        props.onClose()
    }

    const clearFilterHandler = () => {
        dispatch(filtersActions.resetRemindersFilter())
        props.onClose()
    }

      
    return <FilterModal onClose={props.onClose} onClear={clearFilterHandler} onApply={searchFilterHandler}>
        <form className={classes['filter-form']} onSubmit={searchFilterHandler}>
        <h3 className={classes['date-range__header']}>Date</h3>
            <div className={classes['date-range']}>
                <Input label='From' type='date' max="9999-12-31" value={filters.DateFrom} onChange={changeDateFromHandler} invalid={dateRangeInvalid} className={classes['date-range__input']}/>
                <Input label='To' type='date' max="9999-12-31" value={filters.DateTo} onChange={changeDateToHandler} invalid={dateRangeInvalid} className={classes['date-range__input']}/>
            </div>
            <IdsInput label='Reminder' value={filters.ReminderIds} onChange={(value) => setFilters(prevState => ({...prevState, ReminderIds: value}))} className={classes.reminderids}/>
            <FilterByDonor onChange={(value) => setFilters(prevState => ({...prevState, Donors: value}))} initialIds={filters.Donors} />
            <ReminderNames onChange={(value) => setFilters(prevState => ({...prevState, Name: value}))} name={filters.Name} className={classes.name}/>
            <ReminderTypes onChange={(value) => setFilters(prevState => ({...prevState, Type: value}))} type={filters.Type} className={classes.type}/>
            <Input label='Description' value={filters.Description} onChange={(value) => setFilters(prevState => ({...prevState, Description: value}))} className={classes.description}/>
            <FilterByCampaign onChange={(value) => setFilters(prevState => ({...prevState, Campaigns: value}))} initialCampaignIds={filters.Campaigns} />
            <CustomSelect label='Status' options={REMINDER_STATUSES} selectedValue={filters.Status} onChange={(value) => setFilters(prevState => ({...prevState, Status: value}))} className={classes.status}/>
            <button type="submit" style={{ display: 'none' }}>Submit</button>
            </form>
    </FilterModal>
}
export default FilterReminders