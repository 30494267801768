import Input from "./Input"

const IdsInput = ({label, value, onChange, className}) => {

    const changeInputHandler = (value) => {
        const validInput = /^[0-9, ]*$/.test(value);
        if(validInput){
            onChange(value)
        }
    }
    return(
        <Input label={`${label} Ids (comma separated)`} placeholder='e.g. 156, 478, 911' onChange={changeInputHandler} value={value} className={className}/>

    )
}

export default IdsInput