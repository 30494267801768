import classes from './SidePopup.module.css';
import successImg from '../../../images/message-success-circle.svg';
import errorImg from '../../../images/message-error.svg';

const SidePopup = ({ status, header, message, onClose }) => {
    return (
        <div className={`${classes.popup} ${status === 'success' ? classes.success : classes.error}`}>
            <img alt='' src={status === 'success' ? successImg : errorImg} />
            <div className={classes.messages}>
                <p className={classes.header}>{header}</p>
                <p className={classes.message}>{message}</p>
            </div>
        </div>
    );
};

export default SidePopup;
