import { useState, useEffect } from "react"

import SearchDonor from "../../Payments/AddPayment/SearchDonor"
import classes from './FilterPayments.module.css'
import remove from '../../../images/Close-dark.svg'

const FilterByDonor = (props) => {

    //#region hooks
    const [selectedDonors, setSelectedDonors] = useState([])

    useEffect(() => {
        props.onChange(selectedDonors)
    }, [selectedDonors])

    useEffect(()=>{
        if(props.initialIds){
            setSelectedDonors(props.initialIds)
        }
    },[props.initialIds])
    //#endregion

    //#region event handlers

    const addDonorHandler = (donor) => {
        console.log(donor)
        if(!selectedDonors.some(item => item.id === donor.value)){
            setSelectedDonors(prevState => [...prevState, {id: donor.value, name: donor.name}])
        }
    }


    const removeDonorHandler = (id) => {
        setSelectedDonors(prevState => prevState.filter(d => d.id !== id))
    }

    //#endregion

    return (
        <div>
            {selectedDonors.map(d => {
               return <div className={classes['selected-donors']} key={d.id}><p>{d.id} {d.name}</p><img onClick={() => removeDonorHandler(d.id)} src={remove} alt=''/></div>
            })}
        <SearchDonor onChange={addDonorHandler} filter/>
        </div>
    )
}
export default FilterByDonor