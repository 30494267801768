
import classes from './Badge.module.css'

const Badge = ({status, className, children}) => {
    var cssClasses
    switch (status) {
        case 'Waiting for support':
            cssClasses = `${classes.badge} ${classes.regular} ${className}`
            break;
        case 'error':
        case 'Deleted':
        case 'Canceled':
            cssClasses = `${classes.badge} ${classes.error} ${className}`
            break;
        case 'success':
        case 'Successful':
        case 'Payment':
        case 'Resolved':
        case 'Complete':
            cssClasses = `${classes.badge} ${classes.success} ${className}`
            break;
        case 'info':
        case 'Charge':
        case 'In Progress':
            cssClasses = `${classes.badge} ${classes.info} ${className}`
            break;
        case 'warning':
            cssClasses = `${classes.badge} ${classes.warning} ${className}`
            break;
        default:
            cssClasses = `${classes.badge} ${classes.warning} ${className}`


    }

    return (
        <label className={`${className} ${cssClasses}`}>{children}</label>
    )
}

export default Badge