
import Button from './Button'
import classes from './ButtonM.module.css'

const ButtonM = ({type = 'button', loading, children, isDelete, onClick, disabled, className}) => {
    return (
        <Button className={`${classes['btn-m']}  ${className}`} 
        type={type} 
        onClick={onClick} 
        disabled={disabled} 
        loading={loading}
        delete={isDelete}>
            {children}
            </Button>
    )
}

export default ButtonM