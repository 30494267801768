import { useNavigate } from 'react-router-dom'

import Badge from '../../../UI/Others/Badge'
import classes from './ImplementedFeature.module.css'

const ImplementedFeature = (props) => {

    const navigate = useNavigate()

    const viewDetailsHandler = () => {
        navigate(`/new-features/feature-details/${props.feature.FeatureID}`)
    }

    return (
        <div className={classes['implemented-feature']}>
            <Badge className={classes.status} status='Resolved'>{props.feature.Status}</Badge>
            <p className={classes.id}>{props.feature.FeatureID}</p>
            <h3 className={classes.summary}>{props.feature.Summary}</h3>
            <h5>{props.feature.Description}</h5>
            <p className={classes.details} onClick={viewDetailsHandler}>View Details</p>
        </div>
    )
}

export default ImplementedFeature