import { hubActions } from './hub-slice'
import { uiActions } from './ui-slice';
import store from './index'
import { authActions } from './auth-slice';


export const fetchDashboards = (onlyAll = false, signal) => {
    return async (dispatch) => {
        const fetchData = async (all) => {
            var bearer = 'Bearer ' + localStorage.getItem('token');
            const url = store.getState().auth.url
            const response = await fetch(url + `api/${all ? 'allTasks' : 'tasks'}`,{
                headers:{
                    'Authorization': bearer,
                }
                ,signal: signal

            });

            if (!response.ok) {
                if(response.status === 401){ dispatch(authActions.logout()) }
                else{
                const m = await response.json()
                let message = m.Message
                if(!m || m === '')
                message = 'Could not fetch dashboards'
                throw new Error(message)
                }
            }
            const data = await response.json()
            return data;
        };

        try {
            let dashboardsData;
            if(!onlyAll){
                dispatch(hubActions.setDashboardsLoading(true))
                dashboardsData = await fetchData()
                dispatch(hubActions.loadDashboards({ dashboards: dashboardsData || [] }))
                dispatch(hubActions.setDashboardsLoading(false))
            }
            dashboardsData = await fetchData(true)
            dispatch(hubActions.loadAllTasks({ dashboards: dashboardsData || [] }))
        } catch (error) {
            if(error.name && error.name === 'AbortError'){

            }
            else{
                dispatch(uiActions.showNotification({status: 'error', message: error.message}))
            }
        }

        finally {
            dispatch(hubActions.setDashboardsLoading(false))

        }
    };
};


export const fetchReminderTypes = () => {
    return async (dispatch) => {
        const fetchData = async () => {
            dispatch(hubActions.setReminderTypesLoading(true))
            var bearer = 'Bearer ' + localStorage.getItem('token');
            const url = store.getState().auth.url
            const response = await fetch(url + 'api/reminderTypes', {
                headers:{
                    'Authorization': bearer,
                }
            });

            if (!response.ok) {
                if(response.status === 401){ dispatch(authActions.logout()) }
                else{ throw new Error('Could not fetch reminder types list.') }
            }
            const data = await response.json()
            return data;
        };

        try {
            const typesData = await fetchData()
            dispatch(hubActions.loadReminderTypes({ types: typesData || [] }))
        } catch (error) {
            dispatch(uiActions.showErrorMessage(error.message))
        }
        finally {
            dispatch(hubActions.setReminderTypesLoading(false))

        }
    };
};

export const fetchReminderNames = () => {
    return async (dispatch) => {
        const fetchData = async () => {
            var bearer = 'Bearer ' + localStorage.getItem('token');
            const url = store.getState().auth.url
            const response = await fetch(url + 'api/reminderNames', {
                headers:{
                    'Authorization': bearer,
                }
            });

            if (!response.ok) {
                if(response.status === 401){ dispatch(authActions.logout()) }
                else{ throw new Error('Could not fetch reminder names list.') }
            }
            const data = await response.json()
            return data;
        };

        try {
            const namesData = await fetchData()
            dispatch(hubActions.loadReminderNames({ names: namesData || [] }))
        } catch (error) {
            dispatch(uiActions.showErrorMessage(error.message))
        }
    };
};


