import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    payments: [],
    loadedPayments: false,
    quantity: 50,
    selectedPayment: {},
    isLoading: false,
    paymentsForFilter: [],
    paymentsForDonor: [],
    paymentsForPledge: [],
    pendingPayment: null,
    recurringPayments: [],
    loadedRecurringPayments: false,
    recurringQuantity: 50,
    selectedRecurringPayment: {},
    recurringIsLoading: false,
    recurringPaymentsForFilter: [],
}

const paymentsSlice = createSlice({
    name: "payments",
    initialState,
    reducers: {
        loadPayments(state, action) {
            state.payments = action.payload.payments;
            state.quantity = action.payload.payments.length
            state.loadedPayments = true;
        },
        showLessRows(state){
            state.payments = state.payments.slice(0, state.quantity - 51)
            state.quantity = state.quantity - 50
        },
        setIsLoading(state, action) {
            state.isLoading = action.payload
        },
        setSelectedPayment(state, action) {
            if (action.payload) {
                const id = action.payload
                const selectedPayment = state.payments.find(payment => payment.PaymentsID === id)
                if (selectedPayment) {
                    state.selectedPayment = selectedPayment
                }
                else if(typeof action.payload === 'object'){
                    state.selectedPayment = action.payload
                }
            }
        },
        resetSelectedPayment(state) {
            state.selectedPayment = {}
        },
        loadPaymentsForDonor(state, action){
            state.paymentsForDonor = action.payload.payments
        },
        resetPaymentsForDonor(state){
            state.paymentsForDonor = []
        },
        loadPaymentsForPledge(state, action){
            state.paymentsForPledge = action.payload.payments
        },
        resetPaymentsForPledge(state){
            state.paymentsForPledge = []
        },
        setPendingPayment(state, action){
            state.pendingPayment = action.payload
        },
        resetPendingPayment(state){
            state.pendingPayment = null
        },
        //recurring payments
        loadRecurringPayments(state, action) {
            state.recurringPayments = action.payload.payments;
            state.loadedRecurringPayments = true;
            state.recurringQuantity = action.payload.payments.length
        },
        showMoreRecurringRows(state) {
            state.recurringQuantity = state.recurringQuantity + 50
        },
        showLessRecurringRows(state){
            state.recurringPayments = state.recurringPayments.slice(0, state.recurringQuantity - 51)
            state.recurringQuantity = state.recurringQuantity - 50
        },
        setRecurringIsLoading(state, action) {
            state.recurringIsLoading = action.payload
        },
        setSelectedRecurringPayment(state, action) {
            if (action.payload) {
                const id = action.payload
                const selectedPayment = state.recurringPayments.find(payment => payment.PledgeRecurringID === id)
                if (selectedPayment) {
                    state.selectedRecurringPayment = selectedPayment
                }
                else if(typeof action.payload === 'object'){
                    state.selectedRecurringPayment = action.payload
                }
            }
        },

        resetSelectedRecPayment(state) {
            state.selectedRecurringPayment = {}
        },
        removeDeletedPayment(state, action){
            state.payments = state.payments.filter(p => p.PaymentsID !== action.payload)
            if(state.payments.length > 0){
                state.payments[0].TotalPayments -= 1
            }
        },
        removeDeletedRecPayment(state, action){
            state.recurringPayments = state.recurringPayments.filter(p => p.PledgeRecurringID !== action.payload)
            if(state.recurringPayments.length > 0){
                state.recurringPayments[0].TotalRecurring -= 1
            }
        },
        resetState:() => initialState,
    },
});

export const paymentsActions = paymentsSlice.actions;

export default paymentsSlice;
