import { useState } from 'react'

import classes from './RemindersList.module.css'
import SortDesc from '../../../images/Sort - Desc.svg'
import SortAsce from '../../../images/Sort - Acse.svg'
import Reminder from './Reminder'

const RemindersList = ({reminders, onLoadMore, onSortList}) => {

    //#region hooks
    const [idArrow, setIdArrow] = useState(SortAsce)
    const [donorIdArrow, setDonorIdArrow] = useState(SortAsce)
    const [donorArrow, setDonorArrow] = useState(SortAsce)
    const [typeArrow, setTypeArrow] = useState(SortAsce)
    const [dateArrow, setDateArrow] = useState(SortAsce)
    const [nameArrow, setNameArrow] = useState(SortAsce)
    const [descriptionArrow, setDescriptionArrow] = useState(SortAsce)
    const [statusArrow, setStatusArrow] = useState(SortAsce)


    //#endregion

    //#region sort by column
    const changeAllArrowsToDown = () => {
        setIdArrow(SortAsce)
        setDonorIdArrow(SortAsce)
        setDonorArrow(SortAsce)
        setTypeArrow(SortAsce)
        setDateArrow(SortAsce)
        setNameArrow(SortAsce)
        setDescriptionArrow(SortAsce)
        setStatusArrow(SortAsce)
    }

    const switchArrowDirection = (state) => {
        if (state === SortAsce) {
            return SortDesc
        }
        return SortAsce
    }


    const sortByColumnHandler = (column) => {
        changeAllArrowsToDown()
        switch (column) {
            case 'reminderId':
                onSortList(11)
                setIdArrow(switchArrowDirection(idArrow))
                break;
            case 'donorId':
                onSortList(4)
                setDonorIdArrow(switchArrowDirection(donorIdArrow))
                break;
            case 'donor':
                onSortList(12)
                setDonorArrow(switchArrowDirection(donorArrow))
                break;
            case 'date':
                onSortList(7)
                setDateArrow(switchArrowDirection(dateArrow))
                break;
            case 'type':
                onSortList(8)
                setTypeArrow(switchArrowDirection(typeArrow))
                break;
            case 'name':
                onSortList(9)
                setNameArrow(switchArrowDirection(nameArrow))
                break;
            case 'description':
                onSortList(10)
                setDescriptionArrow(switchArrowDirection(descriptionArrow))
                break;
            case 'status':
                onSortList(0)
                setStatusArrow(switchArrowDirection(statusArrow))
                break;
            default:
                break;
        }
    }

    //#endregion
    if(reminders.length > 0){
        return (<section className={classes["reminder-wrapper"]}>
        <div className={classes["reminder-list__header"]}>
            <p className={classes.id}>Reminder Id<img src={idArrow} alt='Arrow' onClick={() => sortByColumnHandler('reminderId')} /></p>
            <p className={classes.donorid}>Donor Id<img src={donorIdArrow} alt='Arrow' onClick={() => sortByColumnHandler('donorId')} /></p>
            <p className={classes.donor}>Donor Name<img src={donorArrow} alt='Arrow' onClick={() => sortByColumnHandler('donor')} /></p>
            <p className={classes.name}>Name<img src={nameArrow} alt='Arrow' onClick={() => sortByColumnHandler('name')} /></p>
            <p className={classes.type}>Type<img src={typeArrow} alt='Arrow' onClick={() => sortByColumnHandler('type')} /></p>
            <p className={classes.date}>Date<img src={dateArrow} alt='Arrow' onClick={() => sortByColumnHandler('date')} /></p>
            <p className={classes.status}>Status<img src={statusArrow} alt='Arrow' onClick={() => sortByColumnHandler('status')} /></p>
        </div>
        <div className={classes["reminders-list"]}>
            {reminders.map(reminder => <Reminder
                key={reminder.ReminderID}
                {...reminder}
            />)}
            {reminders[0].TotalReminders > reminders.length && <button className={classes["btn-load-more"]} type="button" onClick={onLoadMore}>Load More</button>}
        </div>
    </section>)
    }
    else{
        return null
    }
}

export default RemindersList