import { Fragment, useCallback, useEffect, useState } from "react"
import ModalDetails from "../../UI/Modals/ModalDetails"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import DetailsItem from "../../UI/Others/DetailsItem"
import useHttp from "../../../hooks/use-http"
import { hubActions } from "../../../store/hub-slice"
import CustomSelect from "../../UI/Dropdowns/CustomSelect"
import Checkbox from "../../UI/Checkboxs/Checkbox"
import DateInput from "../../UI/Inputs/DateInput"
import ChangesMessageBox from "../../UI/MessageBoxs/ChangesMessageBox"
import classes from './ReminderDetails.module.css'
import {  formatDateWithTime } from "../../../functions/Dates/formatDate"
import TextField from "../../UI/Inputs/TextField"
import { uiActions } from "../../../store/ui-slice"
import { getUpdateMessageBody } from "../../../functions/getMessageBody"
import ShulsList from "../../Payments/PaymentsDropdowns/ShulsList"
import FixedRightBar from "../../UI/FixedBottomBar/FixedRightBar"
import SendReminder from "../../Other/SendReminder"
import { fetchRemindersList } from "../../../store/reminders-actions"
import ReminderNames from "../ReminderNames"
import ReminderTypes from "../ReminderTypes"
import DonorsInfo from "../../Payments/Other/DonorsInfo"
import { donorsActions } from "../../../store/donors-slice"
import { fetchDonorsAddressesList, fetchDonorsContactsList, fetchSingleDonorNotForList } from "../../../store/donors-actions"
import { fetchAllTendersLists } from "../../../store/tenders-actions"

const REMINDER_STATUSES = [{value: 0, name: 'Open'}, {value: 1, name: 'Complete'}, {value: 2, name: 'Canceled'}]

const ReminderDetails = () => {

    const donorReminders = useSelector((state) => state.donors.donorReminders)
    const reminders = useSelector((state) => state.reminders.reminders)
    const campaignsList = useSelector((state) => state.campaigns.campaigns)
    const {  users: usersList } = useSelector(state => state.hub);


    const params = useParams()
    const {sendRequest: updateReminder, isLoading } = useHttp()
    const {sendRequest: getUsers } = useHttp()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [reminder, setReminder] = useState()
    const [formChanged, setFormChanged] = useState()
    const [showMessage, setShowMessage] = useState()
    const [users, setUsers] = useState([])
    const [campaigns, setCampaigns] = useState([])
    const [openTab, setOpenTab] = useState('reminder-info')

    const fromRemindersList = window.location.href.includes('reminders-list') //this is when there are 2 way to get here - remindes list and donor details

    useEffect(()=>{
        if(reminder) return
        console.log('this ran')
        if(fromRemindersList){
            if(params.reminderId && reminders.length > 0){
               let r = reminders.find(reminder => reminder.ReminderID === parseInt(params.reminderId))
               if(r){
                   setReminder(r)
                   dispatch(fetchSingleDonorNotForList(r.ID)) 
                }
            }
        }
        else{
            if(params.reminderId && donorReminders.length > 0){
                let r = donorReminders.find(reminder => reminder.ReminderID === parseInt(params.reminderId))
                if(r){
                    setReminder(r)
                }
            }
        }

    },[donorReminders, params, reminders, dispatch, fromRemindersList])

    
    useEffect(()=>{
        let campaigns = campaignsList.map(campaign => {
            return {value: campaign.CampaignID, name: campaign.Name}
        })
        campaigns = [{ value: 0, name: '-'}, ...campaigns]
        setCampaigns(campaigns)
    },[campaignsList])
    
    const handleGetUsersResponse = useCallback((data) => {
        dispatch(hubActions.loadUsers(data))
    },[dispatch])
    
    const fetchUsersList = useCallback(() => {
        getUsers({url:'api/getUsers'}, handleGetUsersResponse)
    } ,[getUsers, handleGetUsersResponse])

    useEffect(()=>{
        if(usersList.length === 0){
            fetchUsersList()
        }
        else{
            let users  = usersList.map(u => {
                return {value: u.UserID, name: u.UserName}
            })
            setUsers(users)
        }
    },[usersList, fetchUsersList])


    
    const showMessageHandler = () => {
        if(formChanged){
            setShowMessage(true)
        }
        else{
            closeDetailsHandler()
        }
    }

    const updateReminderHandler = () => {
        updateReminder({url: '/api/reminders', method: 'POST', body: reminder, headers: { 'Accept': 'application/json', 'content-type': 'application/json' }}, handleUpdateResponse)
    }

    const handleUpdateResponse = (data) => {
        if(data === ''){
            dispatch(uiActions.showSidePopup({status: 'success', header: getUpdateMessageBody('reminder')}))
            dispatch(fetchRemindersList())
            if(showMessage){
                closeDetailsHandler()
            }
            else{
                setFormChanged(false)
            }
        }
        else{
            dispatch(uiActions.showSidePopup({status: 'error', header: getUpdateMessageBody('reminder', false), message: data}))
        }
    }

    const closeDetailsHandler = () => {
        if(fromRemindersList){
            dispatch(donorsActions.resetDonorsInfo())
        }
        navigate(-1)
    }

    const changeShulHandler = (value) => {
        setReminder(prevState => ({...prevState, ShulID: value}))
    }

    const changeHandler = (field, value) => {
        setReminder(prevState => ({...prevState, [field]: value}))
        setFormChanged(true)
    }

    const handleTabClick = (tab) => {
        setOpenTab(openTab === tab ? null : tab);
      };

    const viewDonorsDetailsHandler = () => {
        dispatch(fetchDonorsAddressesList(reminder.ID))
        dispatch(fetchDonorsContactsList(reminder.ID))
        dispatch(fetchAllTendersLists(reminder.ID))
        navigate(`/donors-list/donor-details?reminderId=${reminder.ReminderID}`)

    }
    return (
        <Fragment>{reminder && 
            <ModalDetails title='Reminder' id={reminder.ReminderID} onClose={showMessageHandler}>
                <DetailsItem title='Reminder Info' onClick={() => handleTabClick('reminder-info')} open={openTab === 'reminder-info'} onClose={() => handleTabClick('reminder-info')} changed={formChanged} onUpdate={updateReminderHandler} loading={isLoading}>
                    <div className={classes['first-row']}><p className={classes.id}>Donor ID: {reminder.ID}</p>
                    <p className={classes['add-date']}>{formatDateWithTime(reminder.AddDate)}</p></div>
                    <div className={classes.fields}>
                        <ReminderNames onChange={(value) => changeHandler('Name', value)} className={classes.name} name={reminder.Name}/>
                        <ReminderTypes  onChange={(value) => changeHandler('Type', value)} type={reminder.Type} />
                        <DateInput label='Date' datetime value={reminder.Date} onChange={(value) => changeHandler('Date', value)}/>
                        <TextField label='Description' value={reminder.Description} onChange={(value) => changeHandler('Description', value)}/>
                        <Checkbox id='sendEmail' label='Send Email' checked={reminder.SendEmail} onChange={() => changeHandler('SendEmail', !reminder.SendEmail)}/>
                        <Checkbox id='isFinancial' label='Financial Reminder' checked={reminder.IsFinancial} onChange={() => changeHandler('IsFinancial', !reminder.IsFinancial)}/>
                        <CustomSelect options={users} label='For User' onChange={(id) => changeHandler('ForUser', id)} className='' selectedValue={reminder.ForUser}/>
                        <CustomSelect options={REMINDER_STATUSES} label='Status' onChange={(id) => changeHandler('Status', id)} className='' selectedValue={reminder.Status}/>
                        <CustomSelect options={campaigns} label='Campaign' onChange={(id) => changeHandler('CampaignID', id)} className='' selectedValue={reminder.CampaignID}/>
                        <ShulsList onChange={changeShulHandler} onChangeInput={()=> setFormChanged(true)} shulID={reminder.ShulID}/>
                    </div>
                </DetailsItem>
                <DetailsItem title='Donors Info' onClick={() => handleTabClick('donor-info')} open={openTab === 'donor-info'} onClose={() => handleTabClick('donor-info')}>
                    <DonorsInfo couldChangeAddress={false} hideDetails={!fromRemindersList} onViewDetails={viewDonorsDetailsHandler}/>
                </DetailsItem>                
                <FixedRightBar>
                    <div></div>
                    <div className={classes.buttons}>
                        <SendReminder id={reminder.ReminderID} isEdit/>
                    </div>
                </FixedRightBar>

            </ModalDetails>}
            {showMessage && <ChangesMessageBox onSave={updateReminderHandler} onNoSave={() => closeDetailsHandler(true)} onCancel={() => setShowMessage(false)} />}
        </Fragment>
    )
}

export default ReminderDetails