
import { uiActions } from './ui-slice';
import store from './index'
import { authActions } from './auth-slice';
import { remindersActions } from './reminders-slice';

export const fetchRemindersList = (amountOfRows = 50, orderBy = 0, desc = true, filter = '') => {
    console.log('fetching list')
    return async (dispatch) => {
        const fetchData = async () => {
            dispatch(remindersActions.setIsLoading(true))
            var bearer = 'Bearer ' + localStorage.getItem('token');
            const url = store.getState().auth.url
            const response = await fetch(url + `api/reminders?amountOfRows=${amountOfRows}&orderBy=${orderBy}&desc=${desc}${filter}`,{
                headers:{
                    'Authorization': bearer,
                }
            });

            if (!response.ok) {
                if(response.status === 401){ dispatch(authActions.logout()) }
                else{ throw new Error('Could not fetch reminders list.') }
            }
            const data = await response.json()
            return data;
        };

        try {
            const remindersData = await fetchData()
            dispatch(remindersActions.loadReminders({ reminders: remindersData || [] }))

        } catch (error) {
            dispatch(uiActions.showErrorMessage(error.message))
        }
        finally {
            dispatch(remindersActions.setIsLoading(false))

        }
    };
};

