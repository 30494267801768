import { pledgesActions } from './pledges-slice'
import { uiActions } from './ui-slice';
import store from './index'
import  { authActions } from './auth-slice';

export const fetchPledgesList = (amountOfRows = 50, orderBy = 7, desc = true, filter = '') => {
    return async (dispatch) => {
        const fetchData = async () => {
            dispatch(pledgesActions.setIsLoading(true))
            if(amountOfRows === 50){
                dispatch(pledgesActions.loadPledges({pledges: []}))
            }
            var bearer = 'Bearer ' + localStorage.getItem('token');
            var url = store.getState().auth.url
            url = url + 'api/pledges'
            url = url + `?rows=${amountOfRows >=50 ? amountOfRows : '50'}&orderBy=${orderBy}&desc=${desc}${filter}`
            const response = await fetch(url, {
                headers: {
                    'Authorization': bearer,
                }
            });

            if (!response.ok) {
                if(response.status === 401){
                    dispatch(authActions.logout())
                }
                else{
                    throw new Error('Could not fetch pledges list.')
                }
            }
            const data = await response.json()
            return data;
        };

        try {
            const pledgesData = await fetchData()
            dispatch(pledgesActions.loadPledges({ pledges: pledgesData || [] }))
            dispatch(pledgesActions.setIsLoading(false))

        } catch (error) {
            dispatch(uiActions.showErrorMessage(error.message))
        }
    };
};


export const fetchPledgesForDonor = (donorId) => {
    return async (dispatch) => {
        const fetchData = async () => {
            dispatch(pledgesActions.setPledgeForDonorLoading(true))
            var bearer = 'Bearer ' + localStorage.getItem('token');
            const url = store.getState().auth.url
            const response = await fetch(url + `api/pledges?rows=1000&donors[0]=${donorId}`, {
                headers: {
                    'Authorization': bearer,
                }
            });

            if (!response.ok) {
                if(response.status === 401){ dispatch(authActions.logout()) }
                else{ throw new Error('Could not fetch pledges list.') }
            }
            const data = await response.json()
            return data;
        };

        try {
            const pledgesData = await fetchData()
            dispatch(pledgesActions.loadPledgesForDonor({ pledges: pledgesData || [] }))

        } catch (error) {
            dispatch(uiActions.showErrorMessage(error.message))
        }
        finally{
            dispatch(pledgesActions.setPledgeForDonorLoading(false))
        }
    };
};

export const fetchPledgesForPayment = (paymentId) => {
    return async (dispatch) => {
        const fetchData = async () => {
            dispatch(pledgesActions.setIsLoading(true))
            var bearer = 'Bearer ' + localStorage.getItem('token');
            var url = store.getState().auth.url
            url = url + `api/pledgesforpayment/${paymentId}`
            const response = await fetch(url, {
                headers: {
                    'Authorization': bearer,
                }
            });

            if (!response.ok) {
                if(response.status === 401){ dispatch(authActions.logout()) }
                else{ throw new Error('Could not fetch pledges for payment.') }
            }
            const data = await response.json()
            return data;
        };

        try {
            const pledgesData = await fetchData()
            dispatch(pledgesActions.loadPledgesForPayment({ pledges: pledgesData || [] }))
            dispatch(pledgesActions.setIsLoading(false))

        } catch (error) {
            dispatch(uiActions.showErrorMessage(error.message))
        }
    };
};

export const fetchRecurringPledgesList = (amountOfRows = 50, orderBy = 20, desc = true, filter = '') => {
    return async (dispatch) => {
        const fetchData = async () => {
            dispatch(pledgesActions.setRecurringIsLoading(true))
            if(amountOfRows === 50){
                dispatch(pledgesActions.loadRecurringPledges({ pledges: [] }))
            }
            var bearer = 'Bearer ' + localStorage.getItem('token');
            var url = store.getState().auth.url
            url = url + 'api/recurringpledges'
            url = url + `?rows=${amountOfRows >=50 ? amountOfRows : '50'}&orderBy=${orderBy}&desc=${desc}${filter}`
            const response = await fetch(url, {
                headers: {
                    'Authorization': bearer,
                }
            });

            if (!response.ok) {
                if(response.status === 401){ dispatch(authActions.logout()) }
                else{ throw new Error('Could not fetch recurring pledges list.') }
            }
            const data = await response.json()
            return data;
        };

        try {
            const pledgesData = await fetchData()
            dispatch(pledgesActions.loadRecurringPledges({ pledges: pledgesData || [] }))
            dispatch(pledgesActions.setRecurringIsLoading(false))

        } catch (error) {
            dispatch(uiActions.showErrorMessage(error.message))
        }
    };
};

export const fetchRecurringPledgesForDonor = (donorId) => {
    return async (dispatch) => {
        const fetchData = async () => {
            var bearer = 'Bearer ' + localStorage.getItem('token');
            const url = store.getState().auth.url
            const response = await fetch(url + `api/recpledgesfordonor?rows=1000&donors[0]=${donorId}`, {
                headers: {
                    'Authorization': bearer,
                }
            });

            if (!response.ok) {
                if(response.status === 401){ dispatch(authActions.logout()) }
                else{ throw new Error('Could not fetch recurring pledges list.') }
            }
            const data = await response.json()
            return data;
        };

        try {
            const pledgesData = await fetchData()
            dispatch(pledgesActions.loadRecurringPledgesForDonor({ pledges: pledgesData || [] }))

        } catch (error) {
            dispatch(uiActions.showErrorMessage(error.message))
        }
    };
};

export const fetchPledgesForRecurringPayment = (recPaymentId) => {
    return async (dispatch) => {
        const fetchData = async () => {
            dispatch(pledgesActions.setIsLoading(true))
            var bearer = 'Bearer ' + localStorage.getItem('token');
            var url = store.getState().auth.url
            url = url + `api/pledgesforrecpayment/${recPaymentId}`
            const response = await fetch(url, {
                headers: {
                    'Authorization': bearer,
                }
            });

            if (!response.ok) {
                if(response.status === 401){ dispatch(authActions.logout()) }
                else{ throw new Error('Could not fetch pledges for recurring payment.') }
            }
            const data = await response.json()
            return data;
        };

        try {
            const pledgesData = await fetchData()
            dispatch(pledgesActions.loadPledgesForPayment({ pledges: pledgesData || [] }))
            dispatch(pledgesActions.setIsLoading(false))

        } catch (error) {
            dispatch(uiActions.showErrorMessage(error.message))
        }
    };
};

export const fetchRecurringPledgesForRecurringPayment = (recPaymentId) => {
    return async (dispatch) => {
        const fetchData = async () => {
            dispatch(pledgesActions.setIsLoading(true))
            var bearer = 'Bearer ' + localStorage.getItem('token');
            var url = store.getState().auth.url
            url = url + `api/recpledgesforpayment/${recPaymentId}`
            const response = await fetch(url, {
                headers: {
                    'Authorization': bearer,
                }
            });

            if (!response.ok) {
                if(response.status === 401){ dispatch(authActions.logout()) }
                else{ throw new Error('Could not fetch recurring pledges for recurring payment.') }
            }
            const data = await response.json()
            return data;
        };

        try {
            const pledgesData = await fetchData()
            dispatch(pledgesActions.loadRecurringPledgesForPayment({ pledges: pledgesData || [] }))
            dispatch(pledgesActions.setIsLoading(false))

        } catch (error) {
            dispatch(uiActions.showErrorMessage(error.message))
        }
    };
};

export const fetchRecurringPledgeDetails = (recPledgeId) => {
    return async (dispatch) => {
        const fetchData = async () => {
            var bearer = 'Bearer ' + localStorage.getItem('token');
            var url = store.getState().auth.url
            const response = await fetch(url + `api/recurringpledgedetails/${recPledgeId}`,{
                headers:{
                    'Authorization': bearer,
                }
            });

            if (!response.ok) {
                if(response.status === 401){ dispatch(authActions.logout()) }
                else{ throw new Error('Could not fetch recurring pledge details.') }
            }
            const data = await response.json()
            return data;
        };

        try {
            const pledgesData = await fetchData()
            dispatch(pledgesActions.setSelectedRecurringPledge(pledgesData))

        } catch (error) {
            dispatch(uiActions.showErrorMessage(error.message))
        }
    };
};



