import { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';

const useFetchSortedList = (fetchAction, filter, initialOrderBy = 0, initialDesc = true) => {
    const dispatch = useDispatch();
    const [orderBy, setOrderBy] = useState(initialOrderBy);
    const [desc, setDesc] = useState(initialDesc);

    const fetchData = useCallback(
        (rows = 50, customOrderBy, customDesc) => {
            dispatch(fetchAction(rows, customOrderBy, customDesc, filter));
        },
        [dispatch, fetchAction, filter] // ✅ No dependency on orderBy/desc
    );

    const sortList = (column) => {
        const newDesc = orderBy === column ? !desc : true;
        setOrderBy(column);
        setDesc(newDesc);
        fetchData(50, column, newDesc); // ✅ Calls fetch only once
    };

    const loadMore = (currentRows) => {
        fetchData(currentRows + 50, orderBy, desc); // ✅ Uses latest orderBy & desc
    };

    // Reset sorting when filter changes and fetch data
    useEffect(() => {
        setOrderBy(initialOrderBy);
        setDesc(initialDesc);
        fetchData(50, initialOrderBy, initialDesc);
    }, [filter, fetchData, initialOrderBy, initialDesc]); // ✅ Runs only when filter changes

    return { orderBy, desc, sortList, loadMore };
};

export default useFetchSortedList;
