import { useEffect, useState } from 'react'

import classes from './CustomSelect.module.css'
import Arrow from '../../../images/Arrow - Down small.svg'
import { useRef } from 'react';


const CustomSelect = ({label, options, selectedValue, onChange, className, invalid, inputClassName, required, dropDownClassName}) => {

  const listRef = useRef()

  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(0);

  useEffect(()=>{
      const selected =   options.findIndex(option => option.value === selectedValue)
      if(selected > -1) {
          setSelectedOption(selected)
        }
    },[options, selectedValue])

    useEffect(()=>{
      if(isOptionsOpen){
        //listRef.current.scrollIntoView({behavior: 'smooth'})
      }
    },[isOptionsOpen])

    const optionChanged = (index) => {
     const selected = options[index].value
     if(selectedValue !== selected){
         onChange(selected)
     }}
    

  
  const toggleOptions = () => {
    setIsOptionsOpen(prevState => !prevState);
  };

  const setSelectedThenCloseDropdown = (index) => {
    setSelectedOption(index);
    optionChanged(index)
    setIsOptionsOpen(false);
  };

  const handleKeyDown = (index) => (e) => {
    switch (e.key) {
      case " ":
      case "SpaceBar":
      case "Enter":
        e.preventDefault();
        setSelectedThenCloseDropdown(index);
        break;
      default:
        break;
    }
  };

  const handleListKeyDown = (e) => {
    var index
    switch (e.key) {
      case "Escape":
        e.preventDefault();
        setIsOptionsOpen(false);
        break;
      case "ArrowUp":
        e.preventDefault();
        index = selectedOption - 1 >= 0 ? selectedOption - 1 : options.length - 1
        setSelectedOption(index);
        optionChanged(index)
        break;
      case "ArrowDown":
        e.preventDefault();
        index =  selectedOption === options.length - 1 ? 0 : selectedOption + 1
        setSelectedOption(index);
        optionChanged(index)
        break;
      default:
        break;
    }
  };

  const unFocuseHanler = (event) => {
    if(event.relatedTarget && event.relatedTarget.id  && event.relatedTarget.id === "listItem"){
        return
    }
    setIsOptionsOpen(false)
  }

  return (
    <div className={`${classes['select-wrapper']} ${className}`} >
      <div className={classes['select-container']}  onBlur={unFocuseHanler}>
        <button
          type="button"
          aria-haspopup="listbox"
          aria-expanded={isOptionsOpen}
          className={` ${classes['select-button']} ${isOptionsOpen ? classes['select-expanded'] : ""} ${invalid && classes['invalid-input']}`}
          onClick={toggleOptions}
          onKeyDown={handleListKeyDown}
        >
            <label>{label} {required && <span className='required-input'>*</span>}</label>
            <p className={`${classes.select} ${inputClassName}`}>{options[selectedOption] ? options[selectedOption].name : ''}</p>
            <div className={classes['select-arrow']}><img src={Arrow} alt=''/></div>
        </button>
        <ul
          className={`${classes.options} ${isOptionsOpen ? classes.show : ""} ${dropDownClassName ? dropDownClassName : ''}`}
          role="listbox"
          aria-activedescendant={options[selectedOption] ? options[selectedOption].name : ''}
          tabIndex={-1}
          onKeyDown={handleListKeyDown}
          ref={listRef}
        >
          {options.map((option, index) => (
            <li
              key={option.value}
              id='listItem'
              role="option"
              aria-selected={selectedOption === index}
              tabIndex={0}
              onKeyDown={handleKeyDown(index)}
              onClick={() => {
                setSelectedThenCloseDropdown(index);
              }}
            >
              {option.name}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default CustomSelect
