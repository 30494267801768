
import classes from './FilterButton.module.css'
import Filter from '../../../images/Filter.svg'


const FilterButton = (props) => {
    const cssClasses = props.active ?  `${classes.filter} ${classes['filter--active']} ${props.className}` :
     `${classes.filter} ${props.className}`
    return (
        <div className={cssClasses} onClick={props.onClick}><img src={Filter} alt=''/></div>
    )
}
export default FilterButton