import { useState, useRef, useEffect } from 'react'

import classes from './TaskActions.module.css'
import noteImg from '../../../../images/Notepad.svg'
import call from '../../../../images/Calling.svg'
import message from '../../../../images/Messages, Chat.svg'
import more from '../../../../images/more_vert.svg'
import Note from './Note'
import NumbersPopup from './NumbersPopup'
import MorePopup from './MorePopup'
import useOutsideAlerter from '../../../../hooks/use-outside-alerter'
import useHttp from '../../../../hooks/use-http'
import { uiActions } from '../../../../store/ui-slice'
import { useDispatch } from 'react-redux'
import { fetchDashboards } from '../../../../store/hub-actions'
import { hubActions } from '../../../../store/hub-slice'



const TaskActions = (props) => {

    const [showNote, setShowNote] = useState(false)
    const [showPhoneNumbers, setShowPhoneNumbers] = useState(false)
    const [showMessageNumbers, setShowMessasgeNumbers] = useState(false)
    const [showMore, setShowMore] = useState(false)
    const [note, setNote] = useState('')

    const actionRef = useRef()
    const dispatch = useDispatch()
    const {sendRequest: saveNote, isLoading: saveNoteLoading} = useHttp()

    const {clickedOutside, resetState} = useOutsideAlerter(actionRef)
  
    useEffect(()=>{
      if(clickedOutside){
        switch(actionRef.current.id){
            case 'note':
                setShowNote(false)
                break;
            case 'call':
                setShowPhoneNumbers(false)
                break;
            case 'message':
                setShowMessasgeNumbers(false)
                break;
            case 'more': 
                setShowMore(false)
                break;
            default:
                break;
        }
        resetState()
      }
    },[clickedOutside])

    const saveTaskNote = () => {
        saveNote({ url: `api/saveNote?dashboardSettingId=${props.dashboardSettingId}&id=${props.task.ID}&reminderId=${props.task.ReminderID}&note=${note}`, headers: { 'Accept': 'application/json', 'content-type': 'application/json' }}, handleNoteResponse)

    }

    const handleNoteResponse = (data) => {
        if(data === ''){
            dispatch(uiActions.showSidePopup({status: 'success', header: 'Successfully saved reminder'}))
            dispatch(fetchDashboards(true))
            setNote('')
            setShowNote(false)
        }
        else{
            dispatch(uiActions.showSidePopup({status: 'error', header: 'Error saving reminder', message: data}))
        }
    }
    return(
    <div className={classes.actions}>
            <div className={`${classes.action} ${saveNoteLoading && classes.loading}`} ref={showNote ? actionRef : null} id='note'>
                <img src={noteImg} alt='' onClick={() => setShowNote(prevState => ! prevState)}/>
                {showNote && <Note onClose={() => setShowNote(false)} value={note} onChange={(v) => setNote(v)} onSave={saveTaskNote}/>}
                </div>
            <div className={classes.action} ref={showPhoneNumbers ? actionRef : null} id='call'>
                <img src={call} alt='' onClick={() => setShowPhoneNumbers(prevState => ! prevState)}/>
                {showPhoneNumbers && <NumbersPopup onClose={() => setShowPhoneNumbers(false)} id={props.task.ID} phoneMobile={props.task.PhoneMobile} phoneHome={props.task.PhoneHome} phoneSpouse={props.task.PhoneSpouse} dashboardSettingId={props.dashboardSettingId} reminderId={props.task.ReminderID}/>}
            </div>
            <div className={classes.action} ref={showMessageNumbers ? actionRef : null} id='message'>
                <img src={message} alt='' onClick={() => setShowMessasgeNumbers(prevState => ! prevState)} />
                {showMessageNumbers && <NumbersPopup message onClose={() => setShowMessasgeNumbers(false)} id={props.task.ID} phoneMobile={props.task.PhoneMobile} phoneSpouse={props.task.PhoneSpouse} email={props.task.Email} dashboardSettingId={props.dashboardSettingId}/>}
            </div>
            <div className={classes.action} ref={showMore ? actionRef : null} id='more'>
                <img src={more} alt='' onClick={() => setShowMore(prevState => ! prevState)} />
                {showMore && <MorePopup cancelco={props.cancelco} onClose={() => setShowMore(false)} task={props.task} dashboardSettingId={props.dashboardSettingId} actions={props.actions} onOpenDetails={props.onOpenDetails} workWith={props.workWith}/>}
            </div>
    </div>
            )
}

export default TaskActions