import { createSlice } from "@reduxjs/toolkit";
const initialRemindersFilter = {
    DateFrom: undefined,
    DateTo: undefined,
    Campaigns: [],
    ReminderIds: '',
    Name: '',
    Type: '', 
    Description: '',
    Donors: [],
    Status: -1}
 
const initialState = {
    paymentsFilter: {},
    paymentsFilterString: '',
    paymentsHasFilter: false,
    recPaymentsFilter: {},
    recPaymentsFilterString: '',
    recPaymentsHasFilter: false,
    pledgesFilter: {},
    pledgesFilterString: '',
    pledgesHasFilter: false,
    recPledgesFilter: {},
    recPledgesFilterString: '',
    recPledgesHasFilter: false,
    donorsFilter: {},
    donorsFilterString: '',
    donorHasFilter: false,
    remindersFilter: initialRemindersFilter,
    remindersFilterString: '',
    reminderHasFilter: false
}
const filtersSlice = createSlice({
    name: "filters",
    initialState,
    reducers: {
        setPaymentsFilter(state, action) {
            state.paymentsFilter = action.payload.filter;
            state.paymentsHasFilter = true
        },
        setPaymentsFilterString(state, action) {
            state.paymentsFilterString = action.payload
        },
        resetPaymentsFilter(state) {
            state.paymentsFilter = {}
            state.paymentsFilterString = ''
            state.paymentsHasFilter = false
        },
        setPledgesFilter(state, action) {
            state.pledgesFilter = action.payload.filter;
            state.pledgesHasFilter = true
        },
        setPledgesFilterString(state, action) {
            state.pledgesFilterString = action.payload
        },
        resetPledgesFilter(state) {
            state.pledgesFilter = {}
            state.pledgesFilterString = ''
            state.pledgesHasFilter = false
        },
        setDonorsFilter(state, action){
            state.donorsFilter = action.payload.filter
            state.donorHasFilter = true
        },
        setDonorsFilterString(state, action){
            state.donorsFilterString = action.payload
        },
        resetDonorsFilter(state){
            state.donorsFilter = {}
            state.donorHasFilter = false
            state.donorsFilterString = ''
        },
        setRecPaymentsFilter(state, action) {
            state.recPaymentsFilter = action.payload.filter;
            state.recPaymentsHasFilter = true
        },
        setRecPaymentsFilterString(state, action) {
            state.recPaymentsFilterString = action.payload
        },
        resetRecPaymentsFilter(state) {
            state.recPaymentsFilter = {}
            state.recPaymentsFilterString = ''
            state.recPaymentsHasFilter = false
        },
        setRecPledgesFilter(state, action) {
            state.recPledgesFilter = action.payload.filter;
            state.recPledgesHasFilter = true
        },
        setRecPledgesFilterString(state, action) {
            state.recPledgesFilterString = action.payload
        },
        resetRecPledgesFilter(state) {
            state.recPledgesFilter = {}
            state.recPledgesFilterString = ''
            state.recPledgesHasFilter = false
        },
        setRemindersFilter(state, action) {
            state.remindersFilter = action.payload;
            state.reminderHasFilter = true
        },
        setRemindersFilterString(state, action) {
            state.remindersFilterString = action.payload
        },
        resetRemindersFilter(state) {
            state.remindersFilter = initialRemindersFilter
            state.remindersFilterString = ''
            state.reminderHasFilter = false
        },

        resetState:() => initialState,
    },
});

export const filtersActions = filtersSlice.actions;

export default filtersSlice;
