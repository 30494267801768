import classes from './Tooltip.module.css';

const Tooltip = ({ children, className, tooltipText, top }) => {
  return (
    <div className={`${classes.tooltip} ${className}`}>
      {children}
      <span className={`${classes["tooltip-text"]} ${top ? classes.top : classes.bottom}`}>
        {tooltipText}
      </span>
    </div>
  );
};

export default Tooltip;
