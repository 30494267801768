import { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux/es/exports"

import { donorsActions } from "../../../store/donors-slice"
import { fetchDonorsAddressesList, fetchDonorsContactsList, fetchSingleDonorNotForList } from "../../../store/donors-actions"
import { fetchAllTendersLists } from "../../../store/tenders-actions"
import DonorSearchBox from "../../UI/SearchBoxs/DonorSearchBox/DonorSearchBox"
import classes from './SearchDonor.module.css'
import AddButton from "../../UI/Buttons/AddButton"
import { fetchPledgesForDonor, fetchRecurringPledgesForDonor } from "../../../store/pledges-actions"
import useHttp from "../../../hooks/use-http"

const SearchDonor = (props) => {

    //#region hooks
    const dispatch = useDispatch()
    const [donors, setDonors] = useState([])
    const {sendRequest: searchDonors, isLoading} = useHttp()


    //#endregion

    //#region event handlers
    const setSelectedDonorHandler = (value) => {
        if (value && value > 0) {
            if(props.filter){
                let donor = donors.find(d => d.value === value)
                if(donor){
                    props.onChange(donor)
                }
            }
            else{
                dispatch(fetchSingleDonorNotForList(value))
                dispatch(fetchDonorsAddressesList(value))
                dispatch(fetchDonorsContactsList(value))
                dispatch(fetchAllTendersLists(value))
                dispatch(fetchPledgesForDonor(value))
                dispatch(fetchRecurringPledgesForDonor(value))
            }
        }
    }

    const searchDonorHandler = (value) => {
        setDonors([])
        if(value !== ''){
            searchDonors({url: `api/donors?rows=10000&search=${value}&allDonors=${!props.filter}`}, handleResponse)        }
        
    }

    const handleResponse = (data) => {
        if(data && data.length > 0){
            var list = data.map(donor => {
                return {value: donor.DonorsID, name: donor.FirstName + " " + donor.LastName, phoneHome: donor.PhoneHome, phoneMobile: donor.PhoneMobile, email: donor.Email, address: {Address: donor.Address, City:  donor.City, State: donor.State, Zip: donor.Zip}}
            })
            setDonors(list)
        }
    }

    //#endregion
    
    return (
        <div className={classes['donor-search__wrapper']}>
        <DonorSearchBox list={donors} placeholder='Search Donor' onSelect={setSelectedDonorHandler} className={props.filter ? classes.filter : classes['donor-search']} onChangeSearchbox={searchDonorHandler} loading={isLoading}/>
        {!props.filter && <AddButton className={classes['donor-search__add']} onClick={props.onAddDonor}>Add Donor</AddButton>}</div>
    )
}

export default SearchDonor