
import classes from './Notification.module.css'
import errorCheck from '../../../images/error-check.svg'
import successCheck from '../../../images/success-check.svg'
import close from  '../../../images/Close-dark.svg'
import { Fragment } from 'react'
import ReactDom from 'react-dom'


const Notification = ({message, onClose, children, additionalInfo}) => {
    const portalElement = document.getElementById('overlays')

    let specialClasses = '';

    if (message.status === 'error') {
        specialClasses = classes.error;
    }
    if (message.status === 'success') {
        specialClasses = classes.success;
    }

    const cssClasses = `${classes.notification} ${specialClasses}`;

    return (<Fragment>
        {ReactDom.createPortal(
    
        <div className={classes['notification-wrapper']}>
            <div className={cssClasses}>
                <img className={classes['notification__close']} src={close} onClick={onClose} alt=''/>
                <img src={message.status === 'error' ? errorCheck : successCheck} alt=''/>
                <div className={classes['notification-message']}>
                <h1>{message.title}</h1>
                <p>{message.message}</p>
                {message.link && <p className={classes['notification-link']} onClick={message.onClickLink}>{message.link}</p>}
                </div>
                {additionalInfo}
                <div className={classes['notification-buttons']}>
                {children}
                </div>
            </div>
        </div>, portalElement)}
        </Fragment>
    )
}

export default Notification