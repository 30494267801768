import { Fragment } from 'react'

import classes from './ModalNew.module.css'
import BackdropBlur from './BackdropBlur'
import ModelTitleClose from '../Others/ModelTitleClose'

const ModalNew = (props) => {

    return(
    <Fragment>
        (<BackdropBlur className={props.backdropClassName}>
            <div className={`${classes.modal} ${props.modelClassName}`}>
            <ModelTitleClose title={`${props.notNew ? '' : 'New'} ${props.title}`} onClose={props.onClose}/>
        <div className={`${classes['modal-white']} ${props.className}`}>
            <form>{props.children}</form>
            </div>
            </div>
            </BackdropBlur>, portalElement)
    </Fragment>)
}

export default ModalNew

