import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    reminders: [],
    loadedReminders: false,
    isLoading: false,
    quantity:50
}

const remindersSlice = createSlice({
    name: "reminders",
    initialState,
    reducers: {
        loadReminders(state, action) {
            state.reminders = action.payload.reminders;
            state.loadedReminders = true;
            state.quantity = action.payload.reminders.length
        },
        setIsLoading(state, action) {
            state.isLoading = action.payload
        },
        resetState:() => initialState,
    },
});

export const remindersActions = remindersSlice.actions;

export default remindersSlice;
