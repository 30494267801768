import { Fragment } from 'react'
import ReactDom from 'react-dom'

import classes from './MessageBox.module.css'
import close from '../../../images/Close-dark.svg'
import BackdropBlur from './BackdropBlur'

const MessageBox = ({backdropClassName, className, title, onClose, children}) => {
    const portalElement = document.getElementById('overlays')

    return (
        <Fragment>
            {ReactDom.createPortal(<BackdropBlur className={`${classes['message-wrapper']} ${backdropClassName}`}>
            <div className={`${classes.message} ${className}`}>
                <div className={classes['message-header']}>
                <h3 className={classes['message-title']}>{title}</h3>
                <img className={classes['message-close']} src={close} onClick={onClose} alt=''/>
                </div>
                {children}
                </div>
                <div>
                </div>
            </BackdropBlur>, portalElement)}
        </Fragment>
    )
}

export default MessageBox