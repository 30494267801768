import { useState, useRef, useEffect, forwardRef } from 'react'

import Dropdown from '../../../images/Arrow - Down small.svg'
import classes from './MultiSelect.module.css'
import Close from '../../../images/Close-dark.svg'
import CheckboxS from '../Checkboxs/CheckboxS'


const MultiSelect = forwardRef((props, ref) => {
    const inputRef = useRef()
    const itemRef = useRef()

    const [items, setItems] = useState([])
    const [isOptionsOpen, setIsOptionsOpen] = useState(false)
    const [cursor, setcursor] = useState(0)
    const [inputValue, setInputValue] = useState('')

    useEffect(() => {
        var itemComponent = itemRef;
        if (itemComponent && itemComponent.current) {
            itemComponent.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'center',
            })
        }
    }, [cursor])

    useEffect(()=>{
        if(props.options && props.options.length > 0){
            setItems(props.options)
        }
    },[props.options])

    useEffect(() => {
        if (!isOptionsOpen) {
            setcursor(0)
        }
    }, [isOptionsOpen])

    const changeInputHandler = (e) => {
        const list = props.options.filter(option => option.label.toLowerCase().includes(e.target.value.toLowerCase()))
        setItems(list)
        setInputValue(e.target.value)
        setIsOptionsOpen(true)
    }

    const unselectItemHandler = (id) => {
        props.toggleOption({ id: id })
        setInputValue('')
        setItems(props.options)
        // inputRef.current.focus()
    }


    // const focusInputHandler = (e) => {
    //    console.log(e)
    //    e.stopPropagation()
    //    setOptionsOpened(true)
    //}



    const optionChanged = (index) => {
        const id = items[index].id
        props.toggleOption({ id: id })
        setInputValue('')
    }
    const setSelectedThenCloseDropdown = (index) => {
        optionChanged(index)
    };


    const handleKeyDown = (index) => (e) => {
        switch (e.key) {
            case " ":
            case "SpaceBar":
            case "Enter":
                e.preventDefault();
                setSelectedThenCloseDropdown(index);
                break;
            default:
                break;
        }
    };

    const inputKeyDownHandler = (e) => {
        if (e.keyCode === 38 && cursor > 0) {
            setcursor(prevState => prevState - 1)
        }
        else if (e.keyCode === 40 && cursor < items.length - 1) {
            setcursor(prevState => prevState + 1)

        }
        else if (e.key === 'Enter') {
            setSelectedThenCloseDropdown(cursor)
        }
    }

    const unFocuseHandler = () => {
        setIsOptionsOpen(false)
        setInputValue('')
    }
    return (
        <div className={`${classes["c-multi-select-dropdown"]} ${props.className}`} onBlur={unFocuseHandler} ref={ref}>
            <div className={`${classes["multi-select__input"]} ${props.invalid && classes['invalid-input']} ${props.inputClassName}`}>
                <label className={classes['multi-select__label']}>{props.label} {props.required && <span className='required-input'>*</span>}</label>
                <div className={classes['multi-select__selected']}>{props.selected.map((option, index) => {
                    return <div className={classes['multi-select__selected-option']} key={index}><p>{option.label}</p><div onClick={() => unselectItemHandler(option.id)}><img src={Close} alt='' /></div></div>
                })}
                <input ref={inputRef} onChange={changeInputHandler} value={inputValue} onFocus={changeInputHandler} onKeyDown={inputKeyDownHandler} />
                </div>
                <div className={classes['multi-select__arrow']} onClick={() => {setIsOptionsOpen(prevState => !prevState); inputRef.current.focus()}}>
                    {props.selected.length > 0 && <img className={classes['multi-select__clear']} onClick={props.clearFilter} src={Close} alt="arrow"/>}
                    <img src={Dropdown} alt='' />
                </div>
            </div>
            {isOptionsOpen &&
                <ul className={`${classes["multi-select__options"]} `}>
                    {items.map((option, index) => {
                        const isSelected = props.selected.some(e => e.id === option.id)
                        return (
                            <li
                                className={`${classes["multi-select__option"]} ${cursor === index ? classes.active : ''}`}
                                onMouseDown={() => setSelectedThenCloseDropdown(index)}
                                onKeyDown={handleKeyDown(index)}
                                key={option.id}
                                ref={cursor === index ? itemRef : null}
                                id='listItem'>
                                <span>{option.label}</span>
                                <CheckboxS checked={isSelected} className={classes["c-multi-select-dropdown__option-checkbox"]} onChange={()=>{}}/>
                            </li>
                        )
                    })}
                </ul>}
        </div>
    )
})

export default MultiSelect