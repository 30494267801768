
export const getUpdateMessageBody = (obj, success = true) => {
    if(success){
        return `Successfully updated ${obj}`
    }
    else{
        return `Error updating ${obj}`
    }
}


export const getAddMessageBody = (obj, success = true) => {
    if(success){
        return `Successfully added ${obj}`
    }
    else{
        return `Error saving ${obj}`
    }
}

export const getSaveMessageBody = (obj, success = true) => {
    if(success){
        return `Successfully saved ${obj}`
    }
    else{
        return `Error saving ${obj}`
    }
}

export const getDeleteMessageBody = (obj, success = true) => {
    if(success){
        return `Successfully deleted ${obj}`
    }
    else{
        return `Error deleting ${obj}`
    }
}

export const getRestoreMessageBody = (obj, success = true) => {
    if(success){
        return `Successfully restored ${obj}`
    }
    else{
        return `Error restoring ${obj}`
    }
}

export const getSentMessageBody = (obj, success = true) => {
    if(success){
        return `Successfully sent ${obj}`
    }
    else{
        return `Error sending ${obj}`
    }
}

export const getCompleteMessageBody = (obj, success = true) => {
    if(success){
        return `Successfully completed ${obj}`
    }
    else{
        return `Error completing ${obj}`
    }
}