import { useDispatch } from "react-redux"
import useHttp from "../../hooks/use-http"
import ButtonMOut from "../UI/Buttons/Buttons-Outline/ButtonMOut"
import classes from './SendReminder.module.css'
import { uiActions } from "../../store/ui-slice"
import { getSentMessageBody } from "../../functions/getMessageBody"
import message from '../../images/Message  green.svg'
import email from '../../images/Email green.svg'
import Tooltip from "../UI/Others/Tooltip"
import { useState } from "react"

const SendReminder = ({id, isPledge, isEdit}) => {
    const {sendRequest, isLoading} = useHttp()
    const dispatch = useDispatch()
    const [isEmail, setIsEmail] = useState(false)

    
    const sendReminderHandler = (value) => {
        setIsEmail(value)
        if(isPledge){
            sendRequest({url: `api/sendPledgeReminder?pledgeId=${id}&isEmail=${value}`}, handleResponse)
        }
        else{
            sendRequest({url: `api/sendReminder?reminderId=${id}&isEmailReminder=${value}`}, handleResponse)
        }
    }

    const handleResponse = (data) =>{
        if(data === ''){
            dispatch(uiActions.showSidePopup({status: 'success', header: getSentMessageBody('reminder')}))
        }
        else{
            dispatch(uiActions.showSidePopup({status: 'error', header: getSentMessageBody('reminder', false), message: data}))
        }
    }
    
    return(
       <div className={`${classes.buttons} ${!isEdit && isPledge  ? classes.top : undefined} ${isLoading ? classes.loading : undefined}`}>
        {!isEdit && isPledge && <Tooltip tooltipText='Send Email Reminder'>
            <img onClick={() => sendReminderHandler(true)} src={email} alt='email'/>
            </Tooltip>}
        {!isEdit && isPledge && <Tooltip tooltipText='Send Text Reminder'><img onClick={() => sendReminderHandler(false)} src={message} alt='text'/></Tooltip>}
        {(isEdit || !isPledge) && <ButtonMOut loading={isLoading && !isEmail}
            disabled={isLoading && !isEmail} 
            onClick={() => sendReminderHandler(false)}>
            Send Text Reminder</ButtonMOut>}
        {(isEdit || !isPledge) && <ButtonMOut loading={isLoading && isEmail} 
            disabled={isLoading && isEmail} 
            onClick={() => sendReminderHandler(true)}>
            Send Email Reminder</ButtonMOut>}
       </div>
    )
}

export default SendReminder