import classes from './TaskActions.module.css'
import ButtonM from '../../../UI/Buttons/Buttons/ButtonM'
import ButtonMOut from '../../../UI/Buttons/Buttons-Outline/ButtonMOut'
import Snooze from '../../../../images/Snooze.svg'
import Message from '../../../../images/Message - w.svg'
import Calling from '../../../../images/Calling - w.svg'
import { useDispatch } from 'react-redux'
import { uiActions } from '../../../../store/ui-slice'
import { fetchDashboards } from '../../../../store/hub-actions'
import useHttp from '../../../../hooks/use-http'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react'
import NumbersPopup from '../TaskActions/NumbersPopup'
import useOutsideAlerter from '../../../../hooks/use-outside-alerter'
import { addDays } from '../../../../functions/Dates/dateFunctions'
import DateInput from '../../../UI/Inputs/DateInput'
import { converDateForBackendWithCurrentTime } from '../../../../functions/Dates/ConvertDateForC#'
import more from '../../../../images/more_vert.svg'
import MorePopup from '../TaskActions/MorePopup'

const TaskActions = (props) => {
    const {sendRequest: completeTask, isLoading: completeTaskLoading} = useHttp()
    const {sendRequest: snoozeTask, isLoading: snoozeTaskLoading} = useHttp()
    const {sendRequest: noResponse, isLoading: noResponseLoading} = useHttp()


    const dispatch = useDispatch()
    const [searchParams] = useSearchParams()
    const actionRef = useRef()
    const {clickedOutside, resetState} = useOutsideAlerter(actionRef)
    const navigate = useNavigate()
    
    const [showPhoneNumbers, setShowPhoneNumbers] = useState()
    const [showMessageNumbers, setShowMessasgeNumbers] = useState()
    const [showSnooze, setShowSnooze] = useState()
    const [showCustomSnooze, setShowCustomSnooze] = useState()
    const [customDueDate, setCustomDueDate] = useState()
    const [showMore, setShowMore] = useState(false)


    useEffect(()=>{
        if(clickedOutside){
          switch(actionRef.current.id){
              case 'call':
                  setShowPhoneNumbers(false)
                  break;
              case 'message':
                  setShowMessasgeNumbers(false)
                  break;
              case 'snooze':
                  setShowSnooze(false)
                  break;
              case 'more': 
                  setShowMore(false)
                  break;
  
              default:
                  break;
          }
          resetState()
        }
      },[clickedOutside])



    const completeHandler = () => {
        if(!completeTaskLoading){
            completeTask({url: `api/completeTask?dashboardSettingId=${searchParams.get('setting')}&id=${searchParams.get('id')}&reminderId=${props.task.ReminderID}`}, handleCompleteTaskResponse)
        }
    }

    const handleCompleteTaskResponse = (data) => {
        if(data === ''){
            dispatch(uiActions.showSidePopup({status: 'success', header: 'Successfuly completed task'}))
            dispatch(fetchDashboards(true))
            handelSnoozedAndCompleted()

        }
    }

    const handelSnoozedAndCompleted = () => {
        if(props.settings && props.settings.TaskCompletedOrSnoozed){
            switch(props.settings.TaskCompletedOrSnoozed){
                case 'Go to List':
                    navigate('..')
                    break;
                case 'Stay on Task':
                    break;
                case 'Go to Next Task':
                    props.onNavigate()
                    break;
                default:
                    break;
            }
        }
    }
    const snoozeHandler = (days) => {
        if(!snoozeTaskLoading){
            let date = new Date()
            date = addDays(date, days)
            snooze(date)
        }
    }

    const snooze = (date) => {
        date = converDateForBackendWithCurrentTime(date)
        snoozeTask({url: `api/snoozeReminder?dashboardSettingId=${searchParams.get('setting')}&id=${searchParams.get('id')}&reminderId=${props.task.ReminderID}&date=${date}`}, handleSnoozeResponse)
    }

    const toggleShowCustomSnoozehandler = () => {
        setShowCustomSnooze(prevState =>  !prevState)
    }

    const handleSnoozeResponse = (data) => {
        if(data === ''){
            dispatch(uiActions.showSidePopup({status: 'success', header: 'Successfuly snoozed task'}))
            dispatch(fetchDashboards(true))
            handelSnoozedAndCompleted()
        }
        setShowSnooze(false)
        setShowCustomSnooze(false)

    }

    const noResponseHandler = () => {
        if(!noResponseLoading){
            noResponse({url: `api/reminderNoResponse?dashboardSettingId=${searchParams.get('setting')}&id=${searchParams.get('id')}&reminderId=${props.task.ReminderID}`}, handleNoResponseResponse)
        }
    }

    const handleNoResponseResponse = (data) => {
        if(data === ''){
            dispatch(uiActions.showSidePopup({status: 'success', header: 'Successfuly updated task'}))
            dispatch(fetchDashboards(true))
            props.onChanged()
        }
    }
        return (
        <div className={classes.actions}>
            <div className={classes.line}>
            <div className={classes.action} ref={showMessageNumbers ? actionRef : null} id='message'>
            <ButtonM onClick={() => setShowMessasgeNumbers(prevState => ! prevState)} className={classes.message}><img src={Message} alt=''/>Message</ButtonM>
                {showMessageNumbers && <NumbersPopup message onClose={() => setShowMessasgeNumbers(false)} id={props.task.ID} phoneMobile={props.task.PhoneMobile} phoneSpouse={props.task.PhoneSpouse} email={props.task.Email} dashboardSettingId={parseInt(searchParams.get('setting'))}/>}
            </div>
            <div className={classes.action} ref={showPhoneNumbers ? actionRef : null} id='call'>
                <ButtonM className={classes.call} onClick={() => setShowPhoneNumbers(prevState => ! prevState)}><img src={Calling} alt='' />Call</ButtonM>            
                {showPhoneNumbers && <NumbersPopup onClose={() => setShowPhoneNumbers(false)} id={props.task.ID} phoneMobile={props.task.PhoneMobile} phoneHome={props.task.PhoneHome} phoneSpouse={props.task.PhoneSpouse} dashboardSettingId={parseInt(searchParams.get('setting'))} reminderId={props.task.ReminderID} onChanged={props.onChanged}/>}
            </div>
            <div ref={showMore ? actionRef : null} id='more'>
                <div className={classes.more} onClick={() => setShowMore(prevState => ! prevState)}><img src={more} alt=''/></div>
                {showMore && <MorePopup onClose={() => setShowMore(false)} task={props.task} dashboardSettingId={props.dashboardSettingId} actions={props.actions} workWith={props.settings.WorkWith} details/>}
            </div>
            </div>
            <div className={classes.line}>
                <ButtonMOut onClick={noResponseHandler} loading={noResponseLoading}>No Response</ButtonMOut>
                <ButtonMOut onClick={completeHandler} loading={completeTaskLoading}>Mark As Complete</ButtonMOut>
                <div className={classes.action}  ref={showSnooze ? actionRef : null} id='snooze'>
                    <ButtonMOut onClick={() => setShowSnooze(prevState => !prevState)} loading={snoozeTaskLoading}><img src={Snooze} alt=''/></ButtonMOut>
                    {showSnooze && <div className={classes['snooze-popup']}>
                        <p className={classes['popup-item']} onClick={() => snoozeHandler(1)}>24 hours</p>
                        <p className={classes['popup-item']} onClick={() => snoozeHandler(2)}>2 days</p>
                        <p className={classes['popup-item']} onClick={() => snoozeHandler(7)}>1 week</p>
                        <p className={classes['popup-item']} onClick={toggleShowCustomSnoozehandler}>custom</p>
                        {showCustomSnooze && <DateInput label='Due date' value={customDueDate} onChange={(v) => snooze(v)} className={classes['custom-date']}/>}
                    </div>}
                </div>
                
            </div>
        </div>
    )
}

export default TaskActions
