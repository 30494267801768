import { Fragment } from 'react'
import { Outlet } from 'react-router-dom'

import Layout from '../../components/layout/Layout'
import RemindersForm from '../../components/Reminders/RemindersList/RemindersForm'


const Reminders = () => {
    return (
        <Fragment>
            <Layout>
            <RemindersForm/>
            </Layout>
            <Outlet/>
        </Fragment>
            )
}

export default Reminders