
import FixedButtonsBar from './FixedButtonsBar'
import classes from './FixedRightBar.module.css'

const FixedRightBar = (props) => {

    return (
        <FixedButtonsBar className={classes['fixed-bar']}>{props.children}</FixedButtonsBar>
    )
}

export default FixedRightBar