import { Fragment, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useSelector } from "react-redux"

import ModalDetails from "../../../UI/Modals/ModalDetails"
import useHttp from "../../../../hooks/use-http"
import DetailsItem from "../../../UI/Others/DetailsItem"
import Badge from "../../../UI/Others/Badge"
import TextField from "../../../UI/Inputs/TextField"
import classes from './NewFeatureDetails.module.css'
import ButtonM from "../../../UI/Buttons/Buttons/ButtonM"
import GrayTextButton from "../../../UI/Buttons/TextButtons/GrayTextButton"
import ImageUpload from "../../../UI/Others/ImageUpload"
import LoadingSpinner from "../../../UI/LoadingSpinners/LoadingSpinner"
import FixedRightBar from "../../../UI/FixedBottomBar/FixedRightBar"

const NewFeatureDetails = () => {
    const navigate = useNavigate()
    const {isLoading, sendRequest: getFeature} = useHttp()
    const {isLoading: savingCommentLoading, sendRequest: saveComment} = useHttp()

    const url = useSelector((state) => state.auth.url)

    const params = useParams()

    const [openTab, setOpenTab] = useState('details')
    const [attachmentsIsLoading, setAttachmentsIsLoading] = useState()
    const [selectedFeature, setSelectedFeature] = useState()
    const [showAddComment, setShowAddComment] = useState()
    const [newComment, setNewComment] = useState('')
    const [errorSaving, setErrorSaving] = useState()
    const [uploadedImages, setUploadedImages] = useState([])
    const [comments, setComments] = useState([])

    useEffect(()=>{
        if(params.id){
            getFeature({url:`api/new-feature-details/${params.id}`}, handleResponse)
        }
    },[params.id, getFeature])

    useEffect(()=>{
        if(selectedFeature && selectedFeature.Comments && selectedFeature.Comments.length > 0&& !savingCommentLoading){
            //setup the comments
            var setupComments = selectedFeature.Comments.map((c,i )=> {
            const value = c.Content.substring(c.Content.indexOf(':') + 2) ?? ''
            return <TextField key={i + Math.random()} className={classes.comment} readOnly value={value} label={c.Content.substring(0, c.Content.indexOf(':') + 1)}>
                {selectedFeature.Attachments && selectedFeature.Attachments.length > 0 && c.AttachmentIds && c.AttachmentIds.length > 0 && c.AttachmentIds.map((a, i) => {
                var attachment = selectedFeature.Attachments.find(att => att.Id === a)
                var bytes = attachment ? attachment.Content : []
                return <img className={classes['comment-attachment']} src={`data:image/jpg;base64, ${bytes}`} alt='' key={a}/>
                })}
            </TextField>})
            setComments(setupComments)
        }
    },[selectedFeature, savingCommentLoading])

    const handleResponse = (data) => {
        if(data){
            setSelectedFeature(data)
        }
    }

   

    const closeDetailsHandler = () => {
        navigate(-1)
    }

    const showAddCommentHandler = (type) => {
        setShowAddComment(type)
    }

    const changeNewCommentHandler = (value) => {
        setNewComment(value)
    }

    const saveCommentHandler = () => {
        if(uploadedImages.length > 0){
            saveAttachments()
        }
        else{
            setComments([])
            saveComment({url: `api/save-feature-comment?featureId=${selectedFeature.FeatureID}&comment=${newComment}`}, handleSaveResponse)
        }
    }

    const saveAttachments = async () => {
        setAttachmentsIsLoading(true)
        var bearer = 'Bearer ' + localStorage.getItem('token');

        var form = new FormData()
        form.append("Comment", newComment)
        form.append("FeatureId", selectedFeature.FeatureID)
        uploadedImages.forEach(image => {
            form.append(image.name, image)

        });
        const response = await fetch(`${url}api/comment-with-attachment`,
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': bearer
                },
                enctype: "multipart/form-data",
                body: form
            })
        const data = await response.json()
       if(data.ActionSuccessful){
        setSelectedFeature(data)
        setShowAddComment(false)
        setNewComment('')
        setUploadedImages([])
        }
        else{
            setErrorSaving(data.ActionResultMessage)
        }
       
        setAttachmentsIsLoading(false)
    }

    const handleSaveResponse = (data) => {
        if(data.ActionSuccessful){
            setSelectedFeature(data)
            setShowAddComment(false)
            setNewComment('')
        }
        else{
            setErrorSaving(data.ActionResultMessage)
        }
    }

    const changeUploadedImages = (files) => {
        setUploadedImages(files)
    }

    const handleTabClick = (tab) => {
        setOpenTab(openTab === tab ? null : tab);
      };


    return (
        <Fragment>
            <ModalDetails title='Feature' id={selectedFeature ? selectedFeature.FeatureID : ''} onClose={closeDetailsHandler}>
            {isLoading && <LoadingSpinner className={classes.spinner}/>}
            {!isLoading && selectedFeature && <DetailsItem title='Feature Details' onClick={() => handleTabClick('details')} open={openTab === 'details'} >
                <h2 className="h2">{selectedFeature.Summary}</h2>
                <p className={classes.description}>{selectedFeature.Description}</p>
                {(selectedFeature.Status === 'In Progress' || selectedFeature.Status === 'Resolved') && <Badge className={classes.status} status={selectedFeature.Status}>{selectedFeature.Status}</Badge>}
            </DetailsItem>}
            {!isLoading && selectedFeature && <DetailsItem title='Comments' onClick={() => handleTabClick('comments')} open={openTab === 'comments'} scroll>
                <div className={classes['add-comments']}>
                    <ButtonM onClick={() => showAddCommentHandler(true)}>Add Comment</ButtonM>
                </div>
                {showAddComment && <ImageUpload className={classes.attachments} title='Add Attachments' onChange={changeUploadedImages} selectedFiles={uploadedImages}/>}
                {showAddComment && <TextField autoFocus className={classes.comment} label={'Add Comment'} value={newComment} onChange={changeNewCommentHandler}/>}
                {showAddComment && <div className={classes['save-comment']}>
                    {errorSaving && <p className="error-message">{errorSaving}</p>}
                     <GrayTextButton onClick={() => setShowAddComment(null)}>Cancel</GrayTextButton>
                     <ButtonM onClick={saveCommentHandler} loading={savingCommentLoading || attachmentsIsLoading}>Save</ButtonM></div>}
                     {comments}
            </DetailsItem>}
            {!isLoading && selectedFeature && <DetailsItem title='Attachments' onClick={() => handleTabClick('attachments')} open={openTab === 'attachments'} scroll>
                {selectedFeature.Attachments && selectedFeature.Attachments.length > 0 && selectedFeature.Attachments.map((a, i) => 
                    <img className={classes['feature-attachment']} src={`data:image/jpg;base64, ${a.Content}`} alt='' key={i}/>
                )}
                </DetailsItem>}
                <FixedRightBar/>
            </ModalDetails>
        </Fragment>
    )
}

export default NewFeatureDetails